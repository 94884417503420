import { FC } from 'react';

import Flex from '../Flex/Flex';
import Spinner from '../Spinner/Spinner';

const PageSpinner: FC = () => (
  <Flex
    center
    width="100%"
    height="100%"
    justifyContent="center"
    zIndex={100}
    position="absolute"
    backgroundColor="neutral.100"
    opacity={0.75}
  >
    <Spinner width="4rem" height="4rem" />
  </Flex>
);

export default PageSpinner;
