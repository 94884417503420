import type * as CSS from 'csstype';
import { parseToRgb } from 'polished';

import { Durations, SpaceValue, Theme } from './theme';

type StylePropValue = string | number | null | undefined;

type PropTransformer<T extends StylePropValue = StylePropValue> = (value: T) => T;

export const makePropTransformer =
  <T extends StylePropValue>(parser: PropTransformer<T>) =>
  (value: T | Record<string | number, T> | T[]): T | Record<string | number, T> | T[] => {
    if (value == null || typeof value == 'string' || typeof value == 'number') {
      return parser(value);
    }

    if (Array.isArray(value)) return value.map(parser);

    return Object.fromEntries(Object.entries(value).map(([key, val]) => [key, parser(val)]));
  };

export const negate = makePropTransformer<string | number>((gap) =>
  typeof gap == 'number' ? gap * -1 : typeof gap == 'string' ? `-${gap}` : gap,
);

export type StyleSelector = (props: Theme | { theme: Theme }) => string;

const createSelector = (selector: (props: Theme) => string): StyleSelector => {
  return (props) => selector('theme' in props ? props.theme : props);
};

const parseSpaceValue =
  (theme: Theme) =>
  (value: string | number | undefined): string => {
    if (value == null) return '';
    if (typeof value == 'string') return value;
    return value < 0 ? `-${theme.space[-value]}` : theme.space[value].toString();
  };

export const getYiq = (red: number, green: number, blue: number): number => {
  // Algorithm for brightness calculation
  // Adapted from: https://www.w3.org/TR/AERT/#color-contrast
  return (red * 299 + green * 587 + blue * 114) / 1000;
};

export const getReadableColor = (color: string): StyleSelector =>
  createSelector((theme) => {
    const { red, green, blue } = parseToRgb(color);
    return getYiq(red, green, blue) >= 128 ? theme.colors.black : theme.colors.white;
  });

export const spacing = (
  m1: SpaceValue,
  m2?: SpaceValue,
  m3?: SpaceValue,
  m4?: SpaceValue,
): StyleSelector =>
  createSelector((theme) =>
    [m1, m2, m3, m4]
      .map(parseSpaceValue(theme))
      .filter((m) => m !== '')
      .join(' '),
  );

export const transition = (
  ...transitions: (keyof CSS.PropertiesHyphen | [keyof CSS.PropertiesHyphen, keyof Durations])[]
): StyleSelector =>
  createSelector((theme) => {
    return transitions
      .map((value) => {
        const [prop, duration]: [keyof CSS.PropertiesHyphen, keyof Durations] =
          typeof value == 'string' ? [value, 'normal'] : value;
        return `${prop} ${theme.durations[duration]}s`;
      })
      .join(', ');
  });
