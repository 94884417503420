import { forwardRef, InputHTMLAttributes } from 'react';
import { Input as ReakitInput } from 'reakit';

import { StandardElementProps } from '../types';

export interface InputBaseProps // Omit<ReakitInputOptions, 'color' | 'height' | 'width'>,
  extends StandardElementProps<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>> {}

const InputBase = forwardRef<HTMLInputElement, InputBaseProps>(function InputBase(
  { as: element = 'input', ...props },
  ref,
) {
  return (
    <ReakitInput
      as={element}
      ref={ref}
      css={{
        border: 0,
        margin: 0,
        outline: 0,
        padding: 0,
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        width: '100%',
      }}
      {...props}
    />
  );
});

export default InputBase;
