import React, { FC, SVGAttributes } from 'react';

import Formic from './Formic.svg';

export interface FormicLogoProps extends SVGAttributes<SVGElement> {}

const FormicLogo: FC<FormicLogoProps> = (props) => {
  return (
    <a href="/">
      <Formic {...props} />
    </a>
  );
};

export default FormicLogo;
