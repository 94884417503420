import { FunctionInterpolation, Interpolation, Theme } from '@emotion/react';
import { styleFn } from 'styled-system';
import warning from 'tiny-warning';

export type PropsWithThemeInterpolation<P extends unknown> = (
  props: P,
) => FunctionInterpolation<Theme>;

export const createSystemStyles =
  <P>(...styleFns: styleFn[]): PropsWithThemeInterpolation<P> =>
  (props) =>
  (theme) => {
    const props2 = typeof props == 'object' ? props : {};
    warning(
      props2 !== theme,
      [
        'The theme object was supplied into both props and theme arguments.',
        'Did you forget to call createSystemStyles with the props from your component?',
      ].join(' '),
    );

    const propsWithTheme = { ...props2, theme };
    return styleFns.reduce<Interpolation<Theme>>(
      (styles, fn) => Object.assign(styles, fn(propsWithTheme)),
      {},
    );
  };
