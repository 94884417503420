import { SpaceValue } from '@formic/styles';
import { forwardRef, OlHTMLAttributes, useMemo } from 'react';

import Box, { BoxProps } from '../Box/Box';
import Card from '../Card/Card';
import ListContext, { defaultValue, ListContextType } from './ListContext';

export type ListVariant = 'none' | 'block' | 'cards';

export interface ListProps extends BoxProps<OlHTMLAttributes<HTMLUListElement | HTMLOListElement>> {
  /**
   * Is this a numbered list?
   */
  ordered?: boolean;
  /**
   * Space between list items.
   */
  itemGap?: SpaceValue;

  variant?: ListVariant;
  horizontal?: boolean;
  listStyle?: 'none' | 'default';
}

const List = forwardRef<HTMLUListElement | HTMLOListElement, ListProps>(function List(
  {
    ordered,
    itemGap = defaultValue.itemGap,
    variant = defaultValue.variant,
    horizontal = defaultValue.horizontal,
    listStyle = 'none',
    children,
    ...props
  },
  ref,
) {
  const contextValue = useMemo<ListContextType>(
    () => ({ itemGap, variant, horizontal }),
    [itemGap, variant, horizontal],
  );
  const Component = variant === 'block' ? Card : Box;

  const showListStyle = listStyle !== 'none';

  return (
    <ListContext.Provider value={contextValue}>
      <Component
        ref={ref}
        as={ordered ? 'ol' : 'ul'}
        my={0}
        mx={showListStyle ? 5 : 0}
        p={0}
        display={horizontal ? 'flex' : undefined}
        css={[
          !showListStyle && {
            // Removing list bullets removes the list semantics in VoiceOver with Safari
            // https://github.com/jensimmons/cssremedy/issues/15
            listStyleType: 'none',
            '&::before': {
              // Add zero-width space
              content: '"\\200B"',
              // Ensures it doesn't cause a gap
              position: 'absolute',
            },
          },
          // itemGap => margin spacing
          itemGap > 0 &&
            ((theme) => {
              const spacing = theme.spacing(itemGap);
              return horizontal
                ? {
                    '& > li': {
                      marginLeft: spacing,
                      marginRight: spacing,
                    },
                    '& > li:first-of-type': {
                      marginLeft: 0,
                    },
                    '& > li:last-of-type': {
                      marginRight: 0,
                    },
                  }
                : {
                    '& > li': {
                      marginTop: spacing,
                      marginBottom: spacing,
                    },
                    '& > li:first-of-type': {
                      marginTop: 0,
                    },
                    '& > li:last-of-type': {
                      marginBottom: 0,
                    },
                  };
            }),
        ]}
        {...props}
      >
        {children}
      </Component>
    </ListContext.Provider>
  );
});

export default List;
