export interface GridAreaNames {
  header: string;
  sidebar: string;
  main: string;
  footer: string;
}

export const gridAreaNames: GridAreaNames = {
  header: 'header',
  sidebar: 'sidebar',
  main: 'main',
  footer: 'footer',
};
