import { pipe } from './function';

export const pxToRem = (pixels: number): string => `${(pixels / 10) * 0.625}rem`;
export const ptToPx = (pt: number): string => `${Math.round(pt * 1.3333)}px`;

export const ptToPxNum = (pt: number): number => Math.round(pt * 1.3333);
// This is from original Web Forms source code so (probably) isn't correct.
export const twipsToPx = (twips: number): number => twips / 13;

const twipsToInches = (twips: number): number => twips / 1440;
const inchToMm = (inches: number): number => inches * 25.4;
export const twipsToMm = pipe(twipsToInches, inchToMm);

//calculates the shadow weight in px
export const shadowWeightToPt = (num: number): string => {
  switch (num) {
    case 1: //None
      return '0pt';
    case 2: //QuarterPoint
      return '0.25pt';
    case 3: //HalfPoint
      return '0.5pt';
    case 4: //ThreeQuarter
      return '0.75pt';
    case 5: //OnePoint
      return '1.0pt';
    case 6: //OneHalfPoint
      return '1.5pt';
    case 7: //TwoQuarterPoint
      return '2.25pt';
    case 8: //ThreePoint
      return '3.0pt';
    case 9: //FourHalfPoint
      return '4.5pt';
    case 10: //SixPoint
      return '6.0pt';
    default:
      return '0pt';
  }
};

export const convertPt = (pt?: string): string => {
  switch (pt) {
    case 'NONE':
      return '0pt';
    case 'QUARTER_POINT':
      return '0.25pt';
    case 'HALF_POINT':
      return '0.5pt';
    case 'THREE_QUARTER':
      return '0.75pt';
    case 'ONE_POINT':
      return '1pt';
    case 'ONE_HALF_POINT':
      return '1.5pt';
    case 'TWO_QUARTER_POINT':
      return '2.25pt';
    case 'THREE_POINT':
      return '3pt';
    case 'FOUR_HALF_POINT':
      return '4.5pt';
    case 'SIX_POINT':
      return '6pt';
    default:
      return '0pt';
  }
};

export const convertToNum = (pt?: string): number => {
  switch (pt) {
    case 'NONE':
      return 0;
    case 'QUARTER_POINT':
      return 0.25;
    case 'HALF_POINT':
      return 0.5;
    case 'THREE_QUARTER':
      return 0.75;
    case 'ONE_POINT':
      return 1;
    case 'ONE_HALF_POINT':
      return 1.5;
    case 'TWO_QUARTER_POINT':
      return 2.25;
    case 'THREE_POINT':
      return 3;
    case 'FOUR_HALF_POINT':
      return 4.5;
    case 'SIX_POINT':
      return 6;
    default:
      return 0;
  }
};
