import './i18n';

import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

// Enable HMR for development
// https://www.snowpack.dev/tutorials/react#making-snowpack-even-faster-with-fast-refresh
// Webpack should strip this out
/* if (import.meta.hot) {
  import.meta.hot.accept();
}
 */
