import { useCallback, useEffect, useState } from 'react';

export interface UseCounterOptions {
  initial?: number;
  min?: number;
  max?: number;
}

export type UseCounterHook = [
  number,
  {
    increment: () => void;
    decrement: () => void;
    reset: () => void;
    set: (newCount: number) => void;
  },
];

export const useCounter = ({
  initial = 0,
  min = -Infinity,
  max = Infinity,
}: UseCounterOptions = {}): UseCounterHook => {
  if (min > max) {
    throw new Error('Minimum cannot be greater than maximum.');
  }

  const [count, setCount] = useState(initial);

  const increment = useCallback(() => {
    setCount((c) => Math.min(c + 1, max));
  }, [max]);

  const decrement = useCallback(() => {
    setCount((c) => Math.max(c - 1, min));
  }, [min]);

  const reset = useCallback(() => {
    setCount(initial);
  }, [initial]);

  const set = useCallback(
    (newCount: number) => {
      if (newCount <= max && newCount >= min) {
        setCount(newCount);
      }
    },
    [max, min],
  );

  useEffect(() => {
    if (count < min) setCount(min);
    if (count > max) setCount(max);
  }, [count, max, min]);

  return [
    count,
    {
      increment,
      decrement,
      reset,
      set,
    },
  ];
};
