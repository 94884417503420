import { useMemo } from 'react';

export const useSearchParams = <T extends Record<string, string> = Record<string, string>>(
  search: string,
): T => {
  return useMemo(() => {
    // URLSearchParams takes care of URL decoding the values for us.
    const urlSearchParams = new URLSearchParams(search);
    return Object.fromEntries(urlSearchParams.entries());
  }, [search]) as T;
};
