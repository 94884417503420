import { getReadableColor, spacing, transition } from '../styleFunctions';
import { Colors } from './Colors';
import { createColors } from './createColors';
import { Theme } from './Theme';

/**
 * Fallback fonts and settings - to be used in font loader
 * Cabin
 * font-family: Tahoma
 * word-spacing: -0.9px / -0.056em
 * letter-spacing: -0.25px / -0.016em
 *
 * PT Sans
 * font-family: Arial
 * word-spacing: 0.9px / 0.056em
 * letter-spacing: -0.3px / -0.019em
 */

const shadowColors = [
  'rgba(0, 0, 0, 0.052)',
  'rgba(0, 0, 0, 0.075)',
  'rgba(0, 0, 0, 0.098)',
  'rgba(0, 0, 0, 0.15)',
];

const createShadow = (...args: [number, number][]): string =>
  args
    .map(([strength, spread], i) => `0 ${strength}px ${strength}px ${spread}px ${shadowColors[i]}`)
    .join(', ');

export const defaultTheme: Theme = {
  box: {
    color: '#121212',
    backgroundColor: '#fff',
  },
  dialog: {
    color: '#121212',
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: 'white', //'#E4EDA6',
    backgroundImage: 'linear-gradient(95deg, rgba(228, 237, 166, 0.19), rgb(228, 237, 166))',
  },
  heading: {
    backgroundColor: 'transparent',
    color: '#191919',
  },
  subHeading: {
    backgroundColor: 'transparent',
    color: '#3B3E3F',
  },
  sidebar: {
    borderRightBgColor: '#E6CFCF',
    backgroundColor: '#ffffff',
  },
  main: {
    backgroundColor: '#F7F7F7',
  },
  page: {
    backgroundColor: '#ffffff',
    errorColor: '#CE2C21',
  },
  spinner: {
    backgroundColor: '#0389bd',
  },
  listItemButton: {
    fontSize: 16,
    color: '#01598C',
    backgroundColor: '#FFF',
    hoverbgColor: '#F2F2F2',
    activebgColor: '#DCDEDE',
  },
  borderStyles: {},
  borders: {},
  breakpoints: ['40em', '52em', '64em', '76em'],
  borderWidths: ['0', '1px'],
  colors: {
    ...(createColors({
      black: '#191919',
      white: '#fff',
      neutral: '#6F7475',
      primary: '#0070A4',
      secondary: '#b2d233',
      danger: '#CE2C21',
      success: '#258227',
      warning: '#FCA800',
      info: '#1276E8',
    }) as Colors),
    readable: function (backgroundColor: string): { backgroundColor: string; color: string } {
      return {
        backgroundColor,
        color: getReadableColor(backgroundColor)({ colors: this } as Theme),
      };
    },
  },
  durations: {
    fast: 0.15,
    normal: 0.2,
    slow: 0.35,
  },
  /**
   * https://type-scale.com/
   * Base size: 16px
   * Scale: 1.250 (Major Third)
   */
  fontSizes: {
    xSmall: '0.64rem',
    small: '0.8rem',
    normal: '1rem',
    medium: '1.125rem',
    large: '1.25rem',
    larger: '1.563rem',
    xLarge: '1.953rem',
    xxLarge: '2.4375rem',
    xxxLarge: '3.052rem',
  },
  fontWeights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  fonts: {
    heading: 'FSMeWebRegular',
  },
  letterSpacings: {},
  lineHeights: {
    normal: 1.4,
  },
  mediaQueries: {},
  radii: ['0', '0.125rem', '0.25rem', '0.5rem'],
  /**
   * Smooth shadows
   * https://brumm.af/shadows
   */
  shadows: [
    'none',
    // 3x3, -1 spread
    createShadow([0.1, -1], [0.4, -1], [0.9, -1], [3, -1]),
    // 9x9, -1 spread
    createShadow([0.4, -1], [1.1, -1], [2.7, -1], [9, -1]),
    // 21x21, -2 spread
    createShadow([1.0, -2], [2.6, -2], [6.3, -2], [21, -2]),
    // 45x45, -2 spread
    createShadow([2.0, -2], [5.6, -2], [13.6, -2], [45, -2]),
    // 93x93, -2 spread
    createShadow([4.2, -2], [11.6, -2], [28, -2], [93, -2]),
  ],
  sizes: {},
  // [0, 2px, 4px, 8px, 16px, 32px, 64px, 128px]
  space: ['0', '0.125rem', '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '8rem'],
  zIndices: {
    drawer: 700,
    modal: 800,
    toast: 900,
    tooltip: 1000,
  },
  spacing: function (m1, m2, m3, m4) {
    return spacing(m1, m2, m3, m4)(this);
  },
  transition: function (...transitions) {
    return transition(...transitions)(this);
  },
  pseudo: {
    disabled: '&:disabled, &[aria-disabled="true"]',
    hover: '&:hover',
    focus: '&:focus',
    active: '&:active',
    hoverFocus: '&:hover, &:focus',
    before: '&::before',
    after: '&::after',
  },
};
