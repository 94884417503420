import { useTheme } from '@emotion/react';
import React, { FC } from 'react';

import Card, { CardProps } from '../Card/Card';
import { gridAreaNames } from '../Container/gridAreaNames';
import CustomLogo from '../CustomLogo/CustomLogo';
import FormicLogo from '../FormicLogo/FormicLogo';
import { mediaQueries } from '../Styles/mediaQueries';

export interface HeaderProps extends CardProps {
  noLogo?: boolean;
}

const Header: FC<HeaderProps> = ({
  children,
  noLogo,
  gridArea = gridAreaNames.header,
  ...props
}) => {
  const selectedTheme = useTheme();

  const logo =
    !noLogo && selectedTheme?.logo ? (
      <CustomLogo />
    ) : (
      <FormicLogo
        css={{
          display: 'block',
          width: 200,
          height: 56,
        }}
        aria-label="Formic Solutions"
      />
    );

  return (
    <Card
      as="header"
      elevation={2}
      px={4}
      py={3}
      gridArea={gridArea}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      css={(theme) => ({
        ...theme.header,

        '> a': {
          [`${mediaQueries.small}`]: {
            display: 'none',
          },
        },
      })}
      zIndex={500}
      {...props}
    >
      {logo}
      {children}
    </Card>
  );
};

export default Header;
