import { Exchange, Operation } from 'urql';
import { filter, fromPromise, map, merge, mergeMap, onPush, pipe, share, takeUntil } from 'wonka';

const addTokenToOperation = (operation: Operation, token?: string) => {
  if (!token) return operation;

  const fetchOptions =
    typeof operation.context.fetchOptions == 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  const newOperation: Operation = {
    ...operation,
    context: {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };

  return newOperation;
};

// TODO: Consider using @urql/exchange-auth instead.
// https://formidable.com/open-source/urql/docs/advanced/authentication/
export const authExchange =
  (getTokenPromise: () => Promise<string | undefined>): Exchange =>
  ({ forward }) => {
    // Share the same promise with all operations.
    let tokenPromise: Promise<string | undefined> | null;

    // This function receives the operations from the previous exchanges.
    return (operations) => {
      // Share the operations so we can use it more than once.
      const shared = pipe(operations, share);

      const withToken = pipe(
        shared,
        filter((operation) => operation.kind !== 'teardown'),
        mergeMap((operation) => {
          // Set a user request if there isn't already one.
          if (!tokenPromise) tokenPromise = getTokenPromise();

          // Listen for cancellations for this operation.
          const teardown = pipe(
            shared,
            filter((op) => op.kind === 'teardown' && op.key === operation.key),
          );

          return pipe(
            // All operations will essentially be queued here until this promise resolves.
            fromPromise(tokenPromise),
            // Clear the resolved promise.
            onPush(() => (tokenPromise = null)),
            // Don't forward the operation if it was cancelled while the token was fetching.
            takeUntil(teardown),
            // Add the token to the operation.
            map((token) => addTokenToOperation(operation, token)),
          );
        }),
      );

      // Teardowns don't need a token so just forward them on.
      const withoutToken = pipe(
        shared,
        filter((operation) => operation.kind === 'teardown'),
      );

      return pipe(merge([withToken, withoutToken]), forward);
    };
  };
