import { Box, Button, Dialog, Icon } from '@formic/components';
import React, { useState } from 'react';
import { EyeOff } from 'react-feather';
import env from 'src/env';

import survey from '../../icons/survey.svg';
import { useHomeScreenThemesQuery } from '../MainLayout.gql';
import { DEFAULT_THEME_DESCRIPTION, DEFAULT_THEME_NAME } from './constants';

export const ThemeSetting: React.FC = () => {
  const defaultThemeId = Number(env.FUSION_OF_DEFAULT_THEME);
  const defaultSelectedTheme = defaultThemeId > 0 ? `${defaultThemeId}` : '';

  const [loadThemes, setLoadThemes] = useState(false);

  const localStorageTheme = localStorage.getItem('FORMIC_WEB_THEME');
  const [selectedTheme, setSelectedTheme] = useState(
    !localStorageTheme ? defaultSelectedTheme : localStorageTheme,
  );

  const fill: { [key: string]: string } = {
    default: 'white',
    light: 'white',
    dark: 'black',
    custom: '#fdf6ae',
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ data, fetching }] = useHomeScreenThemesQuery({
    pause: !loadThemes,
  });

  const handleOnClick = () => {
    setLoadThemes(!loadThemes);
  };

  const handleOnSave = () => {
    localStorage.setItem('FORMIC_WEB_THEME', selectedTheme);
    window.location.reload();
  };

  const handleOnSelect = (id: string) => {
    setSelectedTheme(id === selectedTheme ? defaultSelectedTheme : id);
  };

  return (
    <Dialog
      css={(theme) => ({ backgroundColor: theme.dialog?.backgroundColor, width: 'max-content' })}
      overflow="hidden"
      role="dialog"
      hideOnClickOutside={false}
      disclosure={
        <Button color="primary" iconStart={<EyeOff />} onClick={handleOnClick}>
          Themes
        </Button>
      }
      dialogTitle="THEMES"
      actionButtons={(dialog) => [
        <Button key="cancel" onClick={dialog.hide}>
          Cancel
        </Button>,
        <Button
          key="save"
          color="primary"
          ml={1}
          onClick={(evt) => {
            dialog.hide();
            handleOnSave();
            evt.preventDefault();
          }}
        >
          Save
        </Button>,
      ]}
    >
      <fieldset role="group" css={{ margin: 0, padding: 0, border: 0 }}>
        <legend>{`Please select a theme; selection will display its description.`}</legend>

        <Box overflow="auto">
          <Box
            display={'inline-flex'}
            alignItems="center"
            flexWrap="wrap"
            maxWidth={405}
            height={150}
          >
            {defaultThemeId === 0 && (
              <Button
                type="button"
                focusable
                m={2}
                height={100}
                width={120}
                display={'flex'}
                flexDirection="column"
                alignItems={'center'}
                onClick={() => handleOnSelect('')}
                color={selectedTheme === '' ? 'primary' : undefined}
                aria-label={`${DEFAULT_THEME_NAME} ${DEFAULT_THEME_DESCRIPTION}`}
                aria-pressed={selectedTheme === ''}
              >
                <Icon
                  mb={1}
                  height={'90%'}
                  width={'90%'}
                  css={{ fill: fill['default'] }}
                  component={survey}
                />
                <span>{DEFAULT_THEME_NAME}</span>
              </Button>
            )}
            {data?.themes.map((theme) => (
              <Button
                key={theme.id}
                type="button"
                focusable
                m={2}
                height={100}
                width={120}
                display={'flex'}
                flexDirection="column"
                alignItems={'center'}
                onClick={() => handleOnSelect(theme.id.toString())}
                color={selectedTheme === `${theme.id}` ? 'primary' : undefined}
                aria-label={`${theme.name} ${theme.description}`}
                aria-pressed={selectedTheme === `${theme.id}`}
              >
                <Icon
                  mb={1}
                  height={'90%'}
                  width={'90%'}
                  css={{ fill: fill[theme.name] }}
                  component={survey}
                />
                <span>{theme.name}</span>
              </Button>
            ))}
          </Box>
        </Box>
        <Box maxWidth={405} height={150} m={4}>
          {selectedTheme === ''
            ? DEFAULT_THEME_DESCRIPTION
            : data?.themes.find((t) => t.id.toString() === selectedTheme)?.description}
        </Box>
      </fieldset>
    </Dialog>
  );
};
