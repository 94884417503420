import { shouldForwardProps } from '@formic/styles';
import { FC, HTMLAttributes, ReactElement, ReactNode, ReactText, RefAttributes } from 'react';
import { X } from 'react-feather';
import { isElement } from 'react-is';
import { animated, to, useSpring } from 'react-spring';
import { useUID } from 'react-uid';
import {
  Dialog as ReakitDialog,
  DialogBackdrop,
  DialogDisclosure,
  DialogStateReturn,
  useDialogState,
} from 'reakit';

import Box, { BoxProps } from '../Box/Box';
import DialogButtonRow from '../DialogButtonRow/DialogButtonRow';
import Flex from '../Flex/Flex';
import Heading from '../Heading/Heading';
import IconButton from '../IconButton/IconButton';
import { emotionCloneElement } from '../utils';

type DialogRenderProps = DialogStateReturn;

export interface DialogProps extends BoxProps {
  children: ReactNode | ((dialog: DialogRenderProps) => ReactElement);
  disclosure: ReactElement & RefAttributes<unknown>;
  dialogTitle?: ReactText | ReactElement<HTMLAttributes<Element>>;
  closeButton?: boolean;
  hideOnClickOutside?: boolean;
  actionButtons?: ReactNode | ((dialog: DialogRenderProps) => ReactElement);
  disclosureDefaultPrevented?: boolean;
}

const AnimatedBox = animated(Box);
const AnimatedBackdrop = animated(DialogBackdrop);

const Dialog: FC<DialogProps> = ({
  children,
  disclosure,
  dialogTitle,
  closeButton,
  actionButtons,
  hideOnClickOutside = true,
  disclosureDefaultPrevented,
  ...props
}) => {
  const dialog = useDialogState({ animated: true });
  const { opacity, scaling, offsetX } = useSpring({
    opacity: dialog.visible ? 1 : 0,
    scaling: dialog.visible ? 1 : 1.1,
    offsetX: dialog.visible ? 0 : 10,
    config: {
      tension: 260,
      friction: 32,
    },
    onRest: dialog.stopAnimation,
  });

  const labelId = `dialog-title-${useUID()}`;
  const titleId = isElement(dialogTitle) ? dialogTitle.props.id : labelId;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dialog.setVisible(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const disprops = !disclosureDefaultPrevented
    ? disclosure.props
    : { ...disclosure.props, ...{ onClick: handleClick } };

  return (
    <>
      <DialogDisclosure {...dialog} ref={disclosure.ref} {...disprops}>
        {(disclosureProps) => emotionCloneElement(disclosure, disclosureProps)}
      </DialogDisclosure>
      <AnimatedBackdrop
        css={(theme) => ({
          backgroundColor: 'rgba(0, 0, 0, 0.21)',
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: theme.zIndices.modal,
        })}
        {...dialog}
        style={{ opacity }}
      />
      <ReakitDialog
        as={AnimatedBox}
        hideOnClickOutside={hideOnClickOutside}
        css={(theme) => ({
          position: 'fixed',
          left: '50%',
          top: '50%',
          zIndex: theme.zIndices.modal,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: theme.radii[1],
          boxShadow: theme.shadows[4],
          backgroundColor: theme.colors.white,
          maxHeight: '105vh',
          maxWidth: props.maxWidth ? (props.maxWidth as string) : '80vw',
          width: props.width ? (props.width as string) : '',

          '&:focus': {
            outline: 'none',
          },
        })}
        aria-labelledby={dialogTitle && titleId}
        {...dialog}
        {...shouldForwardProps(props)}
        style={{
          ...props.style,
          opacity,
          transform: to(
            [offsetX, scaling],
            (x, s) => `translate(-50%, calc(-50% - ${x}px)) scale(${s})`,
          ),
        }}
      >
        {
          <Flex
            align="center"
            justify="flex-end"
            mx={4}
            mt={4}
            mb={dialogTitle || closeButton ? 2 : undefined}
          >
            {dialogTitle && (
              <Heading
                id={titleId}
                level="h1"
                variant="h4"
                flexGrow={1}
                m={0}
                css={(theme) => ({ color: theme.heading?.color })}
              >
                {dialogTitle}
              </Heading>
            )}
            {closeButton && (
              <IconButton
                css={(theme) => ({ color: theme.dialog?.color })}
                aria-label="Close dialog"
                onClick={dialog.hide}
                p={1}
                width="1.5em"
                height="1.5em"
              >
                <X />
              </IconButton>
            )}
          </Flex>
        }
        <Box flexGrow={1} mx={4} mb={4} css={(theme) => ({ color: theme.box?.color })}>
          {children instanceof Function ? children(dialog) : children}
        </Box>
        {actionButtons && (
          <DialogButtonRow mx={4} mb={4}>
            {actionButtons instanceof Function ? actionButtons(dialog) : actionButtons}
          </DialogButtonRow>
        )}
      </ReakitDialog>
    </>
  );
};

export default Dialog;
