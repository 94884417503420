import { useEffect, useRef } from 'react';

export const useTitle = (title: string): void => {
  const initialTitle = useRef<string>(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    return () => {
      document.title = initialTitle.current;
    };
  }, []);
};
