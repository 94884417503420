import { Global, ThemeProvider } from '@emotion/react';
import { HeaderUserStatus } from '@formic/auth';
import { Box, GlobalStyles, Header, Util } from '@formic/components';
import { createTheme } from '@formic/styles';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import SkipToMain from 'src/components/SkipToMain';
import env from 'src/env';
import { useHomeScreenOptionsQuery } from 'src/screens/Home/Home.gql';

import { useHomeScreenThemesByIdQuery } from './MainLayout.gql';
import { ThemeSetting } from './ThemeSetting/ThemeSetting';

const MainLayout: FC = ({ children }) => {
  const [homeScreenOptions] = useHomeScreenOptionsQuery();
  const { pathname, search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const receipt = queryParams.get('receipt');

  const isInProjectPage = /\/(\d+)$/.exec(pathname);

  let projectId: number = 0;
  if (isInProjectPage) {
    projectId = Number(isInProjectPage[1]);
  }

  const prjHideUIOptions = Util.useSearchProjectHiddenUIOptions(projectId);

  const defaultThemeId = Number(env.FUSION_OF_DEFAULT_THEME);

  const themeId = Number(localStorage.getItem('FORMIC_WEB_THEME'));

  const [{ data, fetching }] = useHomeScreenThemesByIdQuery({
    variables: { themeId: themeId !== 0 ? themeId : defaultThemeId },
    pause: defaultThemeId === 0 && themeId === 0,
  });

  if (homeScreenOptions.fetching || fetching) return null;

  const formicTheme = !data?.theme.data ? createTheme() : createTheme(JSON.parse(data.theme.data));

  let showHeader: boolean = true;
  if (isInProjectPage && receipt) {
    showHeader = true;
  } else if (!isInProjectPage) {
    showHeader = true;
  } else if (prjHideUIOptions && prjHideUIOptions.hideHeader) {
    showHeader = false;
  }

  return (
    <ThemeProvider theme={formicTheme}>
      <GlobalStyles />
      <Global
        styles={{
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
          },
          '#root': {
            height: '100%',
            width: '100%',
          },
        }}
      />
      <SkipToMain />
      <Box display="flex" flexDirection="column" height="100%">
        {showHeader && (
          <Header className="header" css={{ '@media print': { display: 'none' } }}>
            <HeaderUserStatus
              loginEnabled={homeScreenOptions.data?.onlineFormsOptions.loginEnabled}
              registerEnabled={homeScreenOptions.data?.onlineFormsOptions.registrationEnabled}
            >
              <ThemeSetting />
            </HeaderUserStatus>
          </Header>
        )}
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default MainLayout;
