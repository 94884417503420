import type { Exchange, OperationResult } from 'urql';
import { filter, fromArray, merge, mergeMap, pipe, share } from 'wonka';

const isProjectTagQuery = (op: OperationResult): boolean => {
  const variables = op.operation.variables;
  // Check that tagName was a variable and that the result contains data.
  return op.operation.kind === 'query' && variables?.identifier?.tagName && op.data?.project.id;
};

const copyTagOperationResultAsIdResult = (op: OperationResult): OperationResult => {
  // Clone the operation and replace the identifier.
  return {
    ...op,
    operation: {
      ...op.operation,
      variables: {
        ...op.operation.variables,
        identifier: {
          id: op.data.project.id,
        },
      },
    },
  };
};

/**
 * An exchange that looks for project requests by tag name and pre-caches a request by project
 * ID for the same project.
 *
 * @returns An exchange.
 */
export const projectTagNameExchange =
  (): Exchange =>
  ({ forward }) => {
    return (operations) => {
      const shared = pipe(forward(operations), share);

      /**
       * Look for successful requests for project data and clone the response, replacing the tagName
       * variable with the returned project ID. This allows for future requests by ID to be served
       * directly from cache instead of requiring another network request.
       */
      const projectDataOps = pipe(
        shared,
        filter((op) => isProjectTagQuery(op)),
        mergeMap((op) => fromArray([op, copyTagOperationResultAsIdResult(op)])),
      );

      const otherOps = pipe(
        shared,
        filter((op) => !isProjectTagQuery(op)),
      );

      return merge([projectDataOps, otherOps]);
    };
  };
