import { forwardRef } from 'react';

import Flex, { FlexProps } from '../Flex/Flex';
import { stringToHslColor } from '../utils';

export interface AvatarProps extends FlexProps {}

const Avatar = forwardRef<HTMLElement, AvatarProps>(function Avatar({ children, ...props }, ref) {
  const backgroundColor =
    !props.backgroundColor &&
    !props.bg &&
    (typeof children == 'string' || typeof children == 'number')
      ? stringToHslColor(children.toString(), 40, 30)
      : undefined;

  return (
    <Flex
      center
      ref={ref}
      width="2em"
      height="2em"
      fontSize="large"
      css={[
        (theme) => ({
          ...((backgroundColor && theme.colors.readable(backgroundColor)) || undefined),
          borderRadius: '50%',
          lineHeight: 1,
        }),
      ]}
      {...props}
    >
      {children}
    </Flex>
  );
});

export default Avatar;
