import { MutableRefObject, Ref, useMemo } from 'react';

const setRef = <T extends unknown>(ref: Ref<T> | undefined, value: T): void => {
  if (typeof ref == 'function') {
    ref(value);
  } else if (ref != null) {
    (ref as MutableRefObject<T>).current = value;
  }
};

export const useMergedRef = <T>(ref1?: Ref<T>, ref2?: Ref<T>): Ref<T> => {
  return useMemo(() => {
    return (value: T) => {
      setRef(ref1, value);
      setRef(ref2, value);
    };
  }, [ref1, ref2]);
};
