import {
  useMultipleSelection,
  UseMultipleSelectionGetDropdownProps,
  UseMultipleSelectionGetSelectedItemPropsOptions,
} from 'downshift';
import { HTMLAttributes, useCallback } from 'react';
import invariant from 'tiny-invariant';

import { setA11yStatus } from './setA11yStatus';

export interface UseSelectionOptions<T> {
  /**
   * Available items in the list.
   */
  items: T[];

  /**
   * Allow multiple selection.
   */
  multiple?: boolean;

  /**
   * Current value of the selection.
   */
  value: T | null | T[];

  /**
   * A function that converts the items into strings to be shown in the input.
   * Optional if `T` is string.
   */
  itemToString?: (item: T | null) => string;

  /**
   * Callback fired when the state of the input changes.
   */
  onChange: (items?: T | T[] | null | undefined) => void;
}

export interface UseSelectionHook<T> {
  getSelectedItemProps: (
    options: UseMultipleSelectionGetSelectedItemPropsOptions<T>,
  ) => HTMLAttributes<HTMLElement>;
  getDropdownProps: (options?: UseMultipleSelectionGetDropdownProps) => HTMLAttributes<HTMLElement>;
  handleItemSelection: (item: T | null | undefined) => void;
  removeSelectedItem: ((item: T) => void) | undefined;
  selectedItems: T[] | null;
}

/**
 * Handles the selection state of items within a selection component.
 *
 * @param options Options defining the selection behaviour.
 * @returns Props to render the current selection state and to manipulate it.
 */
export const useSelection = <T>(options: UseSelectionOptions<T>): UseSelectionHook<T> => {
  const {
    items,
    multiple,
    value = multiple ? [] : null, // Ensure that this is always a controlled component.
    onChange,
  } = options;

  // Ensure that itemToString is not undefined if the values are strings.
  const itemToString =
    options.itemToString || ((item: T | null): string => item as unknown as string);

  if (items.length && typeof items[0] != 'string')
    invariant(
      itemToString,
      'The itemToString option is required when passing in items that are not strings.',
    );

  if (multiple) invariant(Array.isArray(value), 'value must be an array if multiple is true.');
  else invariant(!Array.isArray(value), 'value must not be an array if multiple is true.');

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection<T>(
    multiple
      ? {
          selectedItems: value as T[],
          itemToString,
          onSelectedItemsChange: ({ selectedItems }) => selectedItems && onChange?.(selectedItems),
        }
      : {},
  );

  const handleItemSelection = useCallback(
    (item: T | null | undefined) => {
      if (multiple) {
        // onChange is called by useMultipleSelection
        if (item) {
          // Toggle item selection
          if (selectedItems.includes(item)) {
            removeSelectedItem(item);
          } else {
            setA11yStatus(`${itemToString(item)} has been added.`);
            addSelectedItem(item);
          }
        }
      } else {
        // Call onChange with or without the item - allows for deletes
        onChange?.(item);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addSelectedItem, multiple, onChange, removeSelectedItem, selectedItems],
  );

  return {
    getSelectedItemProps,
    getDropdownProps,
    handleItemSelection,
    removeSelectedItem: multiple ? removeSelectedItem : undefined,
    selectedItems: multiple ? selectedItems : null,
  };
};
