import { Children, forwardRef, ReactElement } from 'react';

import { ButtonProps } from '../Button/Button';
import ButtonBase from '../ButtonBase/ButtonBase';
import { emotionCloneElement } from '../utils';

export interface IconButtonProps extends ButtonProps {
  children?: ReactElement;
}

const IconButton = forwardRef<HTMLElement, IconButtonProps>(function IconButton(
  { children, as: element = 'button', ...props },
  ref,
) {
  return (
    <ButtonBase
      as={element}
      ref={ref}
      width="1em"
      height="1em"
      css={[
        (theme) => ({
          color: 'inherit',
          borderRadius: '50%',
          transition: theme.transition('background-color', 'box-shadow'),
          boxSizing: 'content-box',

          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.21)',
            boxShadow: `0 0 0 1px rgba(0, 0, 0, 0.35)`,
          },

          '&:active': {
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
          },
        }),
      ]}
      {...props}
    >
      {children &&
        Children.only(children) &&
        emotionCloneElement(children, {
          css: {
            width: '100%',
            height: '100%',
          },
        })}
    </ButtonBase>
  );
});

export default IconButton;
