import { Context, useContext } from 'react';

export const makeContextConsumerHook = <T>(
  Context: Context<T>,
  noContextError: string = 'No context.',
): (() => Exclude<T, undefined>) => {
  return () => {
    const ctx = useContext(Context);
    if (ctx === undefined) throw new Error(noContextError);
    return ctx as Exclude<T, undefined>;
  };
};
