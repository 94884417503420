import { ColorVariantProps } from '@formic/styles';
import { forwardRef } from 'react';

import Box, { BoxProps } from '../Box/Box';
import { ButtonBaseProps } from '../ButtonBase/ButtonBase';
import Input, { InputProps } from '../Input/Input';
import SelectToggleButton from '../SelectBase/SelectToggleButton';

export interface SingleComboboxInputProps extends BoxProps, ColorVariantProps {
  isOpen?: boolean;
  inputProps?: InputProps;
  toggleButtonProps?: ButtonBaseProps;
}

const SingleComboboxInput = forwardRef<HTMLElement, SingleComboboxInputProps>(
  function SingleComboboxInput({ color, isOpen, inputProps, toggleButtonProps, ...props }, ref) {
    return (
      <Box css={{ position: 'relative' }} {...props} ref={ref}>
        <Input
          color={color}
          width="100%"
          height="100%"
          {...inputProps}
          value={inputProps?.value || ''}
        />
        <SelectToggleButton {...toggleButtonProps} isOpen={isOpen} aria-label="toggle menu" />
      </Box>
    );
  },
);

export default SingleComboboxInput;
