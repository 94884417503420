import { useTheme } from '@emotion/react';
import { colorVariant, ColorVariantProps, colorVariants, createSystemStyles } from '@formic/styles';
import { AnchorHTMLAttributes, FC, forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import Box, { BoxStyleProps } from '../Box/Box';
import { StandardElementProps2 } from '../types';

type LinkBaseProps = BoxStyleProps &
  ColorVariantProps &
  StandardElementProps2<Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>> & {
    noUnderline?: boolean;
  };

export type LinkProps = LinkBaseProps & (Pick<RouterLinkProps, 'to'> | { href: string });

const Link: FC<LinkProps> = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { noUnderline, as: element, color = 'primary', ...props },
  ref,
) {
  const theme = useTheme();
  return (
    <Box
      as={element || ('to' in props && props.to ? RouterLink : 'a')}
      ref={ref}
      css={[
        noUnderline && {
          textDecoration: 'none',
        },
        createSystemStyles(
          colorVariant(colorVariants, {
            color: 'main',
            '&:active': {
              color: '500',
            },
            '&:link': {
              color: '500',
            },
            '&:visited': {
              color: '500',
            },
            '&:hover': {
              color: '400',
            },
          }),
        )({ color }),
        theme?.header?.linkColor && {
          color: `${theme?.header?.linkColor} !important`,
        },
      ]}
      {...props}
    />
  );
});

export default Link;
