import { shouldForwardProps } from '@formic/styles';
import {
  ElementType,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  RefAttributes,
} from 'react';

export type RootProps<A extends HTMLAttributes<HTMLElement> = HTMLAttributes<HTMLElement>> =
  A extends HTMLAttributes<infer T>
    ? RefAttributes<T> &
        Omit<A, 'as' | 'color' | 'height' | 'width'> & {
          as?: ElementType;
        }
    : never;

const Root = <T extends HTMLAttributes<HTMLElement> = HTMLAttributes<HTMLElement>>(
  props: RootProps<T>,
  ref: ForwardedRef<T>,
): ReactElement => {
  const Component = props.as || 'div';
  return <Component ref={ref} {...shouldForwardProps(props)} />;
};

export default forwardRef(Root) as typeof Root;
