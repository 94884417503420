import { useTheme } from '@emotion/react';
import React, { FC } from 'react';

const CustomLogo: FC = () => {
  const selectedTheme = useTheme();
  const { logo } = selectedTheme;

  return (
    <a href="/">
      <img src={logo?.logoSrc} height={logo?.height} alt={logo?.altText ?? 'Company Logo'}></img>
    </a>
  );
};

export default CustomLogo;
