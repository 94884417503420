import { FC } from 'react';

import Box, { BoxProps } from '../Box/Box';
import { mediaQueries } from '../Styles/mediaQueries';
import { gridAreaNames } from './gridAreaNames';

export interface ContainerProps extends BoxProps {
  layout?: LayoutType;
}

export type LayoutType = 'default' | 'flex' | 'none';

const layoutProps: { [K in LayoutType]: ContainerProps } = {
  default: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateColumns: '16rem minmax(60rem, 1fr)',
    gridTemplateAreas: `'${gridAreaNames.header} ${gridAreaNames.header}' '${gridAreaNames.sidebar} ${gridAreaNames.main}'`,
  },
  flex: {
    width: "100%",
    display:"flex",
  },
  none: {},
};

const Container: FC<ContainerProps> = ({ layout = 'default', ...props }) => (
  <Box 
    css={{      
      [`${mediaQueries.small}`]: {
          flexDirection:'column',
        }
      }
    }
    height='100%'
    overflow='hidden'
    {...layoutProps[layout]} 
    {...props} 
  />
);

export default Container;
