import { SpaceValue } from '@formic/styles';
import { createContext } from 'react';

import { ListVariant } from './List';

export interface ListContextType {
  itemGap: SpaceValue;
  variant: ListVariant;
  horizontal: boolean;
}

export const defaultValue: ListContextType = {
  itemGap: 0,
  variant: 'none',
  horizontal: false,
};

export default createContext<ListContextType>(defaultValue);
