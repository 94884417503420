import { forwardRef } from 'react';

import Flex, { FlexProps } from '../Flex/Flex';

export interface DialogButtonRowProps extends FlexProps {}

const DialogButtonRow = forwardRef<HTMLElement, DialogButtonRowProps>(function DialogButtonRow(
  props,
  ref,
) {
  return (
    <Flex
      ref={ref}
      justify="flex-end"
      mt={3}
      columnGap={2}
      css={(theme) => ({
        borderRadius: theme.radii[1],
      })}
      {...props}
    />
  );
});

export default DialogButtonRow;
