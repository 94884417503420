import { ColorVariantProps } from '@formic/styles';
import { forwardRef, HTMLAttributes } from 'react';

import Checkbox from '../Checkbox/Checkbox';

export interface SelectMenuItemProps
  extends Omit<HTMLAttributes<HTMLLIElement>, 'color'>,
    ColorVariantProps {
  height?: number;
  isHighlighted?: boolean;
  checkboxes?: boolean;
  isChecked?: boolean;
}

const SelectMenuItem = forwardRef<HTMLLIElement, SelectMenuItemProps>(function SelectMenuItem(
  { children, height, isHighlighted, checkboxes, isChecked, color, ...props },
  ref,
) {
  return (
    <li
      ref={ref}
      aria-selected={isChecked}
      css={(theme) => ({
        backgroundColor: isHighlighted ? theme.colors.neutral[100] : 'transparent',
        borderRadius: theme.radii[1],
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height,
        lineHeight: '1rem',
        padding: theme.spacing(0, 2),
        outline: 'none',

        '&:active': {
          backgroundColor: theme.colors.neutral[300],
        },
      })}
      {...props}
    >
      {checkboxes && (
        <Checkbox
          color={color}
          role="presentation"
          checked={isChecked}
          readOnly
          css={(theme) => ({ pointerEvents: 'none', marginRight: theme.space[1] })}
          tabIndex={-1}
        />
      )}
      {children}
    </li>
  );
});

export default SelectMenuItem;
