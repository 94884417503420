export const isEmptyGuid = (guid: string): boolean =>
  guid === '00000000-0000-0000-0000-000000000000';

export const pluralize = (word: string, measure: number, suffix: string = 's'): string => {
  return measure === 1 || measure === -1 ? word : word + suffix;
};

export const stripHtml = (str: string): string => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.body.textContent || '';
};

export const trimTrailingChars = (str: string, char: string): string =>
  str.replace(new RegExp(`(?:${char})+$`), 'g');

export const trimTrailingSlashes = (str: string): string => str.replace(/\/+$/g, '');
