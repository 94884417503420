import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange, UpdatesConfig } from '@urql/exchange-graphcache';
import { FC } from 'react';
import { Client, dedupExchange, Exchange, fetchExchange, Provider as UrqlProvider } from 'urql';

import { authExchange } from './exchanges/authExchange';
import { projectTagNameExchange } from './exchanges/projectTagNameExchange';
import schema from './introspection';
import keyingConfig from './keyingConfig';

export interface ClientOptions {
  url: string;
  updates?: Partial<UpdatesConfig>;
  getToken?: () => Promise<string | undefined>;
  tagNameExchange?: boolean;
}

export const createClient = ({
  url,
  updates,
  getToken,
  tagNameExchange,
}: ClientOptions): Client => {
  const defaultExchanges = [
    dedupExchange,
    cacheExchange({
      keys: keyingConfig,
      updates,
      schema,
    }),
    getToken && authExchange(getToken),
    tagNameExchange && projectTagNameExchange(),
    fetchExchange,
  ].filter(Boolean) as Exchange[];

  const client = new Client({
    url,
    // TODO: __DEV__ variable
    exchanges:
      process.env.NODE_ENV === 'development'
        ? [devtoolsExchange, ...defaultExchanges]
        : defaultExchanges,
  });

  return client;
};

export interface ClientProviderProps {
  client: Client;
}

export const ClientProvider: FC<ClientProviderProps> = ({ children, client }) => {
  return <UrqlProvider value={client}>{children}</UrqlProvider>;
};
