type SortablePropertySelector<T> = keyof T | ((item: T) => T[keyof T]);

type SortPropertyDefinition<T> =
  | SortablePropertySelector<T>
  | { prop: SortablePropertySelector<T>; descending?: boolean };

export const sortAlphabetically =
  <T>(propDefinitions: SortPropertyDefinition<T> | SortPropertyDefinition<T>[]) =>
  (a: T, b: T): number => {
    propDefinitions = Array.isArray(propDefinitions) ? propDefinitions : [propDefinitions];

    for (const definition of propDefinitions) {
      const { prop: selector, descending = false } =
        typeof definition == 'object' ? definition : { prop: definition };

      const propA: unknown = getSortablePropValue(a, selector as keyof T);
      const propB: unknown = getSortablePropValue(b, selector as keyof T);

      const result = getSort(propA, propB, descending);
      if (result !== 0) return result;
    }

    return 0;
  };

const getSortablePropValue = <T>(item: T, selector: SortablePropertySelector<T>): T[keyof T] => {
  const value = typeof selector == 'function' ? selector(item) : item[selector];
  return typeof value == 'string' ? (value.toLowerCase() as unknown as T[keyof T]) : value;
};

const getSort = <T>(propA: T, propB: T, descending: boolean): number => {
  if (descending) {
    if (propA < propB) return 1;
    if (propA > propB) return -1;
    return 0;
  }

  if (propA < propB) return -1;
  if (propA > propB) return 1;
  return 0;
};
