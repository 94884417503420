import { MutableRefObject, Ref } from 'react';

const setRef = <T extends unknown>(ref: Ref<T> | undefined, value: T): void => {
  if (typeof ref == 'function') {
    ref(value);
  } else if (ref != null) {
    (ref as MutableRefObject<T>).current = value;
  }
};

export const mergeRefs = <T extends unknown>(
  ...refs: (Ref<T> | undefined)[]
): ((instance: T | null) => void) => {
  return (value) => {
    refs.forEach((ref) => setRef(ref, value));
  };
};
