import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';

const SkipToMain: FC = () => {
  const linkRef = React.createRef<HTMLAnchorElement>();

  const theme = useTheme();
  const StyledAnchor = styled.a`
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;

    &:focus,
    &:active {
      color: ${theme.box?.color};
      background-color: ${theme.box?.backgroundColor};
      left: auto;
      top: auto;
      width: 20%;
      height: auto;
      overflow: auto;
      margin: 10px 35%;
      padding: 5px;
      text-align: center;
      font-size: 1.2em;
      z-index: 1200;
    }
  `;

  function onClickHandler(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    document.getElementById('maincontent')?.focus();
    linkRef.current?.blur();
  }

  function handleKeyDown(evt: React.KeyboardEvent<HTMLAnchorElement>) {
    if (evt.key === 'Enter' || evt.key === ' ') {
      evt.preventDefault();
      document.getElementById('maincontent')?.focus();
      linkRef.current?.blur();
    }
  }

  return (
    <StyledAnchor
      href="#maincontent"
      onClick={onClickHandler}
      onKeyDown={handleKeyDown}
      ref={linkRef}
    >
      Skip to main content
    </StyledAnchor>
  );
};

export default SkipToMain;

export const setFocusToRoot = (): void => document.getElementById('root')?.focus();
