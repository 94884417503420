import {
  GetInputPropsOptions,
  GetMenuPropsOptions,
  UseComboboxGetComboboxPropsOptions,
  UseComboboxGetLabelPropsOptions,
  UseComboboxGetToggleButtonPropsOptions,
} from 'downshift';
import { ReactElement, ReactNode, useRef } from 'react';

import Box from '../Box/Box';
import Chip, { ChipProps } from '../Chip/Chip';
import FormLabel from '../FormLabel/FormLabel';
import { InputProps } from '../Input/Input';
import { ScrollToItemRef, SelectBaseProps } from '../SelectBase/types';
import { useSelectDisplay } from '../SelectBase/useSelectDisplay';
import SelectMenu from '../SelectMenu/SelectMenu';
import SelectMenuItem from '../SelectMenuItem/SelectMenuItem';
import MultipleComboboxInput from './MultipleComboboxInput';
import SingleComboboxInput from './SingleComboboxInput';
import useComboboxBehavior from './useComboboxBehavior';

export type ComboboxProps<T> = Omit<InputProps, keyof SelectBaseProps<T>> & SelectBaseProps<T>;

const Combobox = <T extends unknown>({
  color = 'primary',
  items,
  multiple,
  // Ensure that this is always a controlled component.
  value = multiple ? [] : null,
  disabled,
  placeholder,
  onChange,
  itemToString,
  label,
  labelProps,
  inputProps,
  menuProps,
  toggleButtonProps,
  inputRef,
  menuRef,
  onMenuStateChange,
  ...comboboxProps
}: ComboboxProps<T>): ReactElement | null => {
  const {
    inputRef: mergedInputRef,
    focusInput,
    menuAttributes,
    menuRef: mergedMenuRef,
    menuStyles,
  } = useSelectDisplay({ inputRef, menuRef });

  const scrollToItemRef = useRef<ScrollToItemRef | null>(null);

  const {
    getSelectedItemProps,
    removeSelectedItem,
    filteredItems,
    getComboboxProps,
    getInputProps,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    isOpen,
    selected,
    getItemProps,
    highlightedIndex,
  } = useComboboxBehavior({
    items,
    onChange,
    value,
    itemToString,
    multiple,
    onMenuStateChange,
    scrollToItemRef,
  });

  const allMenuProps = getMenuProps({
    ...(menuProps as GetMenuPropsOptions),
    ref: mergedMenuRef,
    style: {
      ...menuProps?.style,
      ...menuStyles,
    },
    ...menuAttributes,
  });

  const renderChip = (selectedItem: T, index: number) => {
    const itemText = itemToString
      ? itemToString(selectedItem)
      : (selectedItem as unknown as string);
    return (
      <Chip
        key={`${itemText}-${index}`}
        marginRight={1}
        marginBottom={2}
        paddingY={0}
        paddingX={2}
        onDelete={
          removeSelectedItem
            ? (evt) => {
                // Keyboard events are handled by downshift
                if (evt.nativeEvent instanceof MouseEvent) {
                  removeSelectedItem(selectedItem);
                }
              }
            : undefined
        }
        {...(getSelectedItemProps({ selectedItem, index }) as ChipProps)}
      >
        {itemText}
      </Chip>
    );
  };

  return (
    <>
      {label && (
        <FormLabel {...getLabelProps(labelProps as UseComboboxGetLabelPropsOptions)}>
          {label}
        </FormLabel>
      )}

      {multiple ? (
        <MultipleComboboxInput
          color={color}
          selectedItems={selected as T[]}
          innerInputProps={getInputProps({
            tabIndex: 0,
            placeholder: (selected as T[]).length ? undefined : placeholder,
            disabled,
            ...(inputProps as GetInputPropsOptions),
          })}
          renderSelectedItem={renderChip}
          toggleButtonProps={getToggleButtonProps({ disabled })}
          {...getComboboxProps({
            ...(comboboxProps as UseComboboxGetComboboxPropsOptions),
            ref: mergedInputRef,
            disabled,
          })}
        />
      ) : (
        <SingleComboboxInput
          color={color}
          placeholder={placeholder}
          isOpen={isOpen}
          inputProps={getInputProps({
            disabled,
            placeholder,
            ...(inputProps as GetInputPropsOptions),
            ref: mergedInputRef,
          })}
          toggleButtonProps={getToggleButtonProps({
            ...toggleButtonProps,
            disabled,
          } as UseComboboxGetToggleButtonPropsOptions)}
          {...getComboboxProps(comboboxProps as UseComboboxGetComboboxPropsOptions)}
        />
      )}

      <SelectMenu
        isOpen={isOpen}
        items={filteredItems}
        scrollToItemRef={scrollToItemRef}
        onTabOut={focusInput}
        renderItem={({ index, size, start }) => {
          const item = filteredItems[index];
          const isSelected = multiple ? undefined : filteredItems[index] === (selected as T | null);
          return (
            <SelectMenuItem
              key={index}
              isHighlighted={highlightedIndex === index}
              height={size}
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
              }}
              {...getItemProps({
                item,
                index,
                tabIndex: -1,
                style: { transform: `translateY(${start}px)` },
              })}
            >
              <Box flexGrow={1}>{itemToString ? itemToString(item) : (item as ReactNode)}</Box>
              {isSelected && <Box textColor="neutral.700">(Remove)</Box>}
            </SelectMenuItem>
          );
        }}
        ref={menuRef}
        {...allMenuProps}
      />
    </>
  );
};

export default Combobox;
