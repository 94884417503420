import { mergeRefs } from '../Util';
import { DOMAttributes, ReactElement, RefAttributes } from 'react';
import { useLocation } from 'react-router-dom';
import { SpringValue, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';

import { ListItemButtonProps } from '../ListItemButton/ListItemButton';
import { ListItemLinkProps } from '../ListItemLink/ListItemLink';
import { emotionCloneElement } from '../utils';

export type NavigationItemBaseProps = { isCurrent?: boolean; disabled?: boolean } & (
  | {
      to: string;
      innerProps?: ListItemLinkProps;
    }
  | {
      innerProps?: ListItemButtonProps;
    }
);

export const cloneNavigationItemElement = (
  child: null | undefined | false | ReactElement<NavigationItemBaseProps & RefAttributes<unknown>>,
  pathname: string,
  currentRef: (element: HTMLElement | null) => void,
): ReactElement | null => {
  if (!child) return null;

  const isCurrent = 'to' in child.props && child.props.to === pathname;
  return emotionCloneElement<
    DOMAttributes<unknown> & NavigationItemBaseProps & RefAttributes<unknown>
  >(child, {
    ref: mergeRefs(child.props.ref, isCurrent ? currentRef : undefined),
    isCurrent,
  });
};

export interface UseCurrentIndicatorHook {
  pathname: string;
  containerRef: (element: HTMLElement | SVGElement | null) => void;
  currentRef: (element: HTMLElement | SVGElement | null) => void;
  shift: SpringValue<number>;
  size: SpringValue<number>;
}

export const useCurrentIndicator = (isHorizontal: boolean): UseCurrentIndicatorHook => {
  const { pathname } = useLocation();
  const [containerRef, container] = useMeasure();
  const [currentRef, current] = useMeasure();
  const { shift, size } = useSpring(
    isHorizontal
      ? {
          shift: current.left - container.left,
          size: current.width,
        }
      : {
          shift: current.top - container.top,
          size: current.height,
        },
  );

  return {
    pathname,
    containerRef,
    currentRef,
    shift,
    size,
  };
};
