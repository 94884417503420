import {
  ColorVariantProps,
  createSystemStyles,
  FontSizeProps,
  shouldForwardProps,
  SpaceProps,
  variant,
} from '@formic/styles';
import { forwardRef } from 'react';

import Box, { BoxStyleProps, boxSystemStyles } from '../Box/Box';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import { commonFormControlStyles } from '../utils';

export type InputSizes = 'normal' | 'large' | 'small';

export type InputSizeVariantProps<T extends string = 'size'> = {
  [K in T]?: InputSizes;
};

export interface InputProps
  extends InputBaseProps,
    Omit<BoxStyleProps, 'size'>,
    ColorVariantProps,
    InputSizeVariantProps<'inputSize'> {}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { as: element = 'input', color = 'primary', inputSize = 'normal', ...props },
  ref,
) {
  const BaseComponent = element === 'input' || element === 'textarea' ? InputBase : Box;

  return (
    <BaseComponent
      as={element}
      ref={ref}
      css={[
        commonFormControlStyles(color),
        createSystemStyles(
          boxSystemStyles,
          variant<FontSizeProps & SpaceProps, InputSizes>({
            prop: 'inputSize',
            variants: {
              small: { fontSize: 'small', padding: 2 },
              normal: { fontSize: 'normal', padding: 2 },
              large: { fontSize: 'large', padding: 2 },
            },
          }),
        )({ inputSize, ...props }),
      ]}
      {...shouldForwardProps(props)}
    />
  );
});

export default Input;
