import Fuse from 'fuse.js';
import { useEffect, useRef, useState } from 'react';

export interface UseFuzzySearchOptions<T> {
  fuseOptions?: Fuse.IFuseOptions<T>;
}

const defaultFuseOptions: Fuse.IFuseOptions<unknown> = {
  threshold: 0.0,
  ignoreLocation: true,
};

export const useFuzzySearch = <T>(
  items: T[],
  query?: string,
  { fuseOptions }: UseFuzzySearchOptions<T> = {},
): T[] => {
  const fuse = useRef(new Fuse<T>(items, { ...defaultFuseOptions, ...fuseOptions }));
  const [filtered, setFiltered] = useState<T[]>(items);

  useEffect(() => {
    fuse.current.setCollection(items);
  }, [items]);

  useEffect(() => {
    if (query && items.length) {
      const results = fuse.current.search(query);
      setFiltered(results.map((res) => res.item));
    } else {
      setFiltered(items);
    }
  }, [query, items]);

  return filtered;
};
