import { FunctionInterpolation, jsx, Theme } from '@emotion/react';
import { ColorVariants, stateColorVariants } from '@formic/styles';
import { Attributes, DOMAttributes, ReactElement, ReactNode, RefAttributes } from 'react';
import { isElement } from 'react-is';

import { ComponentOrElement } from './types';

export const renderComponentOrElement = <P extends DOMAttributes<unknown>>(
  ComponentOrElement: ComponentOrElement<P>,
  props: (P & Attributes) | ((currentProps?: P & Attributes) => P & Attributes),
  ...children: ReactNode[]
): ReactElement<P> => {
  return isElement(ComponentOrElement) ? (
    emotionCloneElement(
      ComponentOrElement,
      typeof props == 'function' ? props(ComponentOrElement.props) : props,
      ...children,
    )
  ) : (
    <ComponentOrElement {...(typeof props == 'function' ? props() : props)}>
      {children}
    </ComponentOrElement>
  );
};

export const makeAriaIdAttribute =
  (idPrefix?: string) =>
  (type: 'title' | 'desc' | 'input'): string | undefined =>
    idPrefix && `${idPrefix}-${type}`;

/* export type StandardAttributes<
  T extends HTMLAttributes<HTMLElement> = HTMLAttributes<HTMLElement>
> = T extends HTMLAttributes<infer E> ? T & RefAttributes<E> : never; */

// https://github.com/emotion-js/emotion/issues/1404
export const emotionCloneElement = <P extends DOMAttributes<unknown>>(
  element: ReactElement<P & Attributes> & RefAttributes<unknown>,
  props: P & Attributes,
  ...children: ReactNode[]
): ReturnType<typeof jsx> => {
  const type =
    (element as ReactElement).props['__EMOTION_TYPE_PLEASE_DO_NOT_USE__'] || element.type;
  return jsx<P>(
    type,
    {
      key: element.key,
      ref: element.ref,
      ...element.props,
      ...props,
      /**
       * I can see why I did this at the time but looking at it now it doesn't make sense.
       * The styles passed into cloneElement should override the original element's styles - not the
       * other way around like it's doing at the moment. If you needed to do that you could just
       * include the original element's styles after the new styles:
       *
       * cloneElement(element, { css: [{ ... }, element.props.css] })
       *
       * TODO: Change this and update components that are using it.
       */
      css: [props.css, element.props.css],
    },
    ...children,
  );
};

export const stringToHash = (str: string): number => {
  let hash = 0;
  const strLen = str.length;
  if (!strLen) return hash;
  for (let i = 0; i < strLen; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const stringToHslColor = (str: string, saturation: number, luminance: number): string => {
  const hue = stringToHash(str) % 360;
  return `hsl(${hue},${saturation}%,${luminance}%)`;
};

export const inputFocusShadow = (color: string): string => `0 0 3px 0.5px ${color}`;

// TODO: Move this somewhere more appropriate

export const commonFormControlStyles =
  (color: ColorVariants | 'neutral'): FunctionInterpolation<Theme> =>
  (theme) => {
    const highlight = (stateColorVariants as string[]).includes(color);
    return {
      backgroundColor: theme.colors.white,
      color: theme.colors.black,
      border: `1.5px solid ${theme.colors[highlight ? color : 'neutral'][400]}`,
      borderRadius: theme.radii[1],
      transition: theme.transition('background-color', 'border-color', 'box-shadow'),

      '&:focus, &:focus-within': {
        borderColor: `${theme.colors[color].main}`,
        boxShadow: inputFocusShadow(theme.colors[color][500]),
      },

      '&:hover, &:focus:hover, &:focus-within:hover': {
        borderColor: theme.colors[color][700],
      },

      '&:disabled, &[aria-disabled="true"]': {
        backgroundColor: theme.colors.neutral[100],
        borderColor: theme.colors.neutral[200],
        color: theme.colors.neutral[400],
        cursor: 'not-allowed',
      },
    };
  };
