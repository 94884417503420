import { FieldUpdateResolver, RespondentOperation, SubmissionInput } from '@formic/fusion-graphql';
import { SubmissionResultFragment } from 'src/features/respondent';
import { DeleteRespondentMutation } from 'src/features/respondent/useDeleteRespondent.gql';
import { PartialRespondentsCountDocument } from 'src/screens/Home/components/PartialFormsBadge/PartialFormsBadge.gql';
import { PartialRespondentsQuery } from 'src/screens/Home/PartialForms/PartialForms.gql';

const postRespondentUpdate: FieldUpdateResolver = {
  updateRespondent: (result, args, cache) => {
    const variables = args.submission as SubmissionInput;
    const submission = result.updateRespondent as SubmissionResultFragment;

    const { isSuccess, respondent } = submission;
    const receipt = submission.respondent.receipt;
    if (!isSuccess || !receipt) return;

    if (variables.operation === RespondentOperation.Save) {
      /**
       * Update the list of saved respondents by pushing the new data into the cache.
       * We (should) only need to do this for adding and removing items as Urql's normalized
       * cache will handle updates to existing records.
       */
      cache.updateQuery(
        {
          query: PartialRespondentsCountDocument,
        },
        (data) => {
          const result = data as PartialRespondentsQuery | null;
          if (!result) return result;
          const partialRespondents = result.partialRespondents;

          /**
           * Add the new respondent to the list if it isn't already in there.
           *
           * We know this will represent the completed respondent as we checked isSuccess
           * and receipt above.
           *
           * Note that mutating the data object methods is fine here, see:
           * https://formidable.com/open-source/urql/docs/graphcache/custom-updates/#cacheupdatequery
           */
          if (!partialRespondents.some((respondent) => respondent.receipt === receipt)) {
            partialRespondents.push(respondent);
          }
          return data;
        },
      );
    } else if (variables.operation === RespondentOperation.Submit) {
      /**
       * We also need to handle the case where a respondent will change from saved to
       * submitted and needs to be removed from the PARTIAL_RESPONDENTS_QUERY.
       */
      /**
       * Update the list of saved respondents by removing the data in the cache.
       */
      cache.updateQuery(
        {
          query: PartialRespondentsCountDocument,
        },
        (data) => {
          const result = data as PartialRespondentsQuery | null;
          if (!result) return result;
          const partialRespondents = result.partialRespondents;

          //Remove the respondent in the list if its still existing
          const idxToDelete = partialRespondents.findIndex(
            (respondent) => respondent.receipt === variables.receipt,
          );
          if (idxToDelete >= 0) {
            result.partialRespondents.splice(idxToDelete, 1);
          }
          return data;
        },
      );
    }
  },
  //update the cache when user deleted the response.
  deleteRespondent: (result, args, cache) => {
    const deleteResponse = result as DeleteRespondentMutation;
    const { isSuccess } = deleteResponse.deleteRespondent;

    if (!isSuccess) return;

    cache.updateQuery(
      {
        query: PartialRespondentsCountDocument,
      },
      (data) => {
        const result = data as PartialRespondentsQuery | null;
        if (!result) return result;
        const partialRespondents = result.partialRespondents;

        //Remove the respondent in the list if its still existing
        const idxToDelete = partialRespondents.findIndex(
          (respondent) => respondent.receipt === args.receipt,
        );
        if (idxToDelete >= 0) {
          result.partialRespondents.splice(idxToDelete, 1);
        }
        return data;
      },
    );
  },
};

export default postRespondentUpdate;
