export const pipe = <R extends unknown>(
  fn1: (arg: R) => R,
  ...fns: ((arg: R) => R)[]
): ((arg: R) => R) => {
  return fns.reduce((prevFn, nextFn) => (value) => nextFn(prevFn(value)), fn1);
};

export const compose = <R extends unknown>(
  fn1: (arg: R) => R,
  ...fns: ((arg: R) => R)[]
): ((arg: R) => R) => {
  return fns.reduce((prevFn, nextFn) => (value) => prevFn(nextFn(value)), fn1);
};
