import {
  color,
  ColorProps,
  compose,
  createSystemStyles,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shouldForwardProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  textColor,
} from '@formic/styles';
import { ForwardedRef, forwardRef, HTMLAttributes, ReactElement, RefAttributes } from 'react';

import { StandardElementProps2 } from '../types';

export type BoxStyleProps = ColorProps &
  DisplayProps &
  FlexboxProps &
  FontSizeProps &
  FontWeightProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  TextAlignProps;

export type BoxProps<T extends HTMLAttributes<HTMLElement> = HTMLAttributes<HTMLElement>> =
  BoxStyleProps & StandardElementProps2<T>;

export const boxSystemStyles = compose(
  color,
  display,
  flexbox,
  fontSize,
  fontWeight,
  grid,
  layout,
  position,
  space,
  textColor,
  textAlign,
);

const Box = <T extends HTMLAttributes<HTMLElement>>(
  props: RefAttributes<HTMLElement> & BoxProps<T>,
  ref: ForwardedRef<T>,
): ReactElement | null => {
  const Component = props.as || 'div';
  return (
    <Component
      ref={ref}
      css={createSystemStyles(boxSystemStyles)(props)}
      {...shouldForwardProps(props)}
    />
  );
};

export default forwardRef(Box) as typeof Box;
