import {
  createSystemStyles,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  FontSizeProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  shouldForwardProps,
  space,
  SpaceProps,
  variant,
} from '@formic/styles';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { Button as ReakitButton, ButtonOptions as ReakitButtonOptions } from 'reakit';

import { StandardElementProps2 } from '../types';

export type ButtonSizes = 'normal' | 'large' | 'small';

export interface ButtonSizeVariantProps {
  size?: ButtonSizes;
}

export interface ButtonBaseProps
  extends Omit<ReakitButtonOptions, 'color'>,
    DisplayProps,
    FlexboxProps,
    GridProps,
    Omit<LayoutProps, 'size'>,
    SpaceProps,
    ButtonSizeVariantProps,
    StandardElementProps2<ButtonHTMLAttributes<HTMLButtonElement>> {}

const ButtonBase = forwardRef<HTMLElement, ButtonBaseProps>(function ButtonBase(
  { as: element = 'button', size, ...props },
  ref,
) {
  return (
    <ReakitButton
      as={element}
      ref={ref}
      css={[
        (theme) => ({
          appearance: 'none',
          backgroundColor: 'transparent',
          color: theme.colors.black,
          letterSpacing: 'inherit',
          wordSpacing: 'inherit',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          fontFamily: 'inherit',
          fontSize: 'inherit',
          lineHeight: 1,
          border: 0,
          margin: 0,
          padding: 0,
          outline: 0,
          borderRadius: theme.radii[1],
          fontWeight: theme.fontWeights.medium,
          transition: theme.transition('background-color', 'box-shadow'),
        }),
        createSystemStyles(
          display,
          flexbox,
          grid,
          layout,
          space,
          variant<FontSizeProps & SpaceProps, ButtonSizes>({
            // "size" would conflict with the same prop in LayoutProps
            prop: 'btnSize',
            variants: {
              small: { fontSize: 'small', padding: 2 },
              normal: { fontSize: 'normal', padding: 3 },
              large: { fontSize: 'large', padding: 4 },
            },
          }),
        )({ btnSize: size, ...props }),
      ]}
      {...shouldForwardProps(props)}
    />
  );
});

export default ButtonBase;
