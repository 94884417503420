import { keyframes } from '@emotion/react';
import { forwardRef } from 'react';

import CheckboxBase, { CheckboxBaseProps } from '../CheckboxBase/CheckboxBase';

export interface CheckboxProps extends CheckboxBaseProps {}

const check = keyframes`
  0% { border-bottom-width: 0; border-right-width: 0; }
  25% { border-bottom-width: 0.125rem; border-right-width: 0; }
  50% { border-bottom-width: 0.125rem; border-right-width: 0; }
  75% { border-bottom-width: 0.125rem; border-right-width: 0.125rem; }
  100% { border-bottom-width: 0.125rem; border-right-width: 0.125rem; }
`;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { as: element, ...props },
  ref,
) {
  const color = props.color || 'neutral';

  return (
    <CheckboxBase
      as={element}
      ref={ref}
      css={[
        (theme) => ({
          '&::after': {
            transform: 'rotate(45deg)',
            top: '10%',
            left: '35%',
            right: '35%',
            bottom: '25%',
            borderRight: `0.125rem solid ${theme.colors[color].main}`,
            borderBottom: `0.125rem solid ${theme.colors[color].main}`,
            animation: `${check} 0.2s`,
          },

          '&:hover': {
            '&::after': {
              borderRightColor: theme.colors[color][700],
              borderBottomColor: theme.colors[color][700],
            },
          },
        }),
      ]}
      {...props}
    />
  );
});

export default Checkbox;
