import { ColorVariantProps, createSystemStyles, shouldForwardProps } from '@formic/styles';
import { forwardRef, KeyboardEvent, MouseEvent, useRef } from 'react';
import { X as XIcon } from 'react-feather';
import { Tabbable } from 'reakit';

import { BoxStyleProps, boxSystemStyles } from '../Box/Box';
import { useMergedRef } from '../hooks';
import IconButton from '../IconButton/IconButton';
import { StandardElementProps } from '../types';

export interface ChipProps extends BoxStyleProps, StandardElementProps, ColorVariantProps {
  onDelete?: (evt: KeyboardEvent<HTMLElement> | MouseEvent<HTMLButtonElement>) => void;
}

function isDeleteKeyboardEvent(evt: React.KeyboardEvent<HTMLElement>) {
  return evt.key === 'Backspace' || evt.key === 'Delete';
}

const Chip = forwardRef<HTMLElement, ChipProps>(function Chip(
  { children, as: element = 'div', color, onDelete, ...props },
  ref,
) {
  const chipRef = useRef<HTMLElement | null>(null);
  const mergedRef = useMergedRef(ref, chipRef);

  const handleKeyDown = (evt: KeyboardEvent<HTMLElement>) => {
    // Ignore events from children of `Chip`.
    if (evt.currentTarget === evt.target && isDeleteKeyboardEvent(evt)) {
      // will be handled in keyUp, otherwise some browsers
      // might init navigation
      evt.preventDefault();
    }

    // Call the supplied onKeyDown
    props.onKeyDown?.(evt);
  };

  const handleKeyUp = (evt: KeyboardEvent<HTMLElement>) => {
    // Ignore events from children of `Chip`.
    if (evt.currentTarget === evt.target) {
      if (onDelete && isDeleteKeyboardEvent(evt)) {
        onDelete(evt);
      } else if (evt.key === 'Escape' && chipRef.current) {
        chipRef.current?.blur();
      }
    }

    // Call the supplied onKeyUp
    props.onKeyUp?.(evt);
  };

  const handleDeleteClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    onDelete?.(evt);
  };

  return (
    <Tabbable
      as={element}
      css={[
        (theme) => ({
          borderRadius: theme.radii[1],
          cursor: 'default',
          display: 'flex',
          alignItems: 'center',
          outline: 'none',
          ...theme.colors.readable(theme.colors.neutral[100]),
          border: `1px solid ${theme.colors.neutral[300]}`,
          fontSize: theme.fontSizes.small,
          padding: theme.spacing(1, 2),
          transition: theme.transition('background-color'),

          '&:focus': {
            backgroundColor: theme.colors.neutral[200],
          },
        }),
        color &&
          ((theme) => ({
            ...theme.colors.readable(theme.colors[color][500]),
            borderColor: theme.colors[color][700],
            '&:focus': {
              ...theme.colors.readable(theme.colors[color][600]),
            },
          })),
        createSystemStyles(boxSystemStyles)(props),
      ]}
      {...shouldForwardProps(props)}
      ref={mergedRef}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
    >
      {children}
      {onDelete && (
        <IconButton
          onClick={handleDeleteClick}
          aria-hidden
          css={(theme) => ({
            width: theme.fontSizes.small,
            height: theme.fontSizes.small,
            borderRadius: theme.fontSizes.small,
            boxSizing: 'content-box',
            margin: theme.spacing(-1, -1, -1, 1),
            padding: theme.spacing(1),
          })}
        >
          <XIcon />
        </IconButton>
      )}
    </Tabbable>
  );
});

export default Chip;
