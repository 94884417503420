import { forwardRef } from 'react';

import { BoxProps } from '../Box/Box';
import Card from '../Card/Card';

export interface MenuProps extends BoxProps {}

const Menu = forwardRef<HTMLElement, MenuProps>(function Menu(props, ref) {
  return (
    <Card
      ref={ref}
      css={(theme) => ({
        padding: theme.spacing(1),
        zIndex: theme.zIndices.modal,
      })}
      {...props}
    />
  );
});

export default Menu;
