import { ColorVariantProps } from '@formic/styles';
import { forwardRef, LabelHTMLAttributes } from 'react';

import Box, { BoxStyleProps } from '../Box/Box';
import { StandardElementProps } from '../types';

export interface FormLabelProps
  extends BoxStyleProps,
    StandardElementProps<HTMLLabelElement, LabelHTMLAttributes<HTMLLabelElement>>,
    ColorVariantProps {}

const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(function FormLabel(
  { as: element = 'label', color, ...props },
  ref,
) {
  return (
    <Box
      css={[
        (theme) => ({
          color: theme.colors[color || 'neutral'].main,
        }),
      ]}
      ref={ref}
      as={element}
      {...props}
    />
  );
});

export default FormLabel;
