import {
  forwardRef,
  Fragment,
  ReactElement,
  RefObject,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';
import { Portal } from 'reakit';

import Box from '../Box/Box';
import { useMergedRef } from '../hooks';
import List from '../List/List';
import Menu, { MenuProps } from '../Menu/Menu';
import { ScrollToItemRef } from '../SelectBase/types';

export interface SelectMenuProps<T> extends MenuProps {
  height: number;
  itemHeight: number;
  onTabOut?: () => void;
  isOpen: boolean;
  items: T[];
  noItemsText?: string | ReactElement;
  scrollToItemRef: RefObject<ScrollToItemRef>;
  renderItem: (item: VirtualItem) => ReactElement | null;
}

const SelectMenu = forwardRef<HTMLElement, SelectMenuProps<unknown>>(function SelectMenu(
  {
    height = 300,
    itemHeight = 40,
    onTabOut,
    isOpen,
    items,
    noItemsText = 'No items to select',
    scrollToItemRef,
    renderItem,
    ...props
  },
  ref,
) {
  const virtualContainerRef = useRef<HTMLElement | null>(null);

  const mergedRef = useMergedRef(virtualContainerRef, ref);

  const virtualizer = useVirtual({
    size: items.length,
    parentRef: virtualContainerRef,
    estimateSize: useCallback(() => itemHeight, [itemHeight]),
    overscan: 10,
  });

  useImperativeHandle(
    scrollToItemRef,
    () => ({
      scrollToIndex: (index) => virtualizer.scrollToIndex(index),
    }),
    [virtualizer],
  );

  return (
    <Portal>
      <Menu
        css={(theme) => ({
          display: isOpen ? 'block' : 'none',
          overflow: 'auto',
          outline: 'none',
          backgroundColor: theme.box?.backgroundColor,
          color: theme.box?.color,
        })}
        data-testid="menu"
        {...props}
        ref={mergedRef}
        style={{
          ...props.style,
          height: virtualizer.virtualItems.length * itemHeight < height ? 'auto' : height,
        }}
      >
        {virtualizer.virtualItems.length ? (
          <List
            css={{
              height: `${virtualizer.totalSize}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {virtualizer.virtualItems.map((item) => (
              <Fragment key={item.index}>{renderItem(item)}</Fragment>
            ))}
          </List>
        ) : typeof noItemsText === 'string' ? (
          <Box padding={2} textColor="neutral.main">
            {noItemsText}
          </Box>
        ) : (
          noItemsText
        )}
      </Menu>

      {onTabOut && <div onFocus={onTabOut} tabIndex={0} />}
    </Portal>
  );
});

export default SelectMenu;
