import { FunctionInterpolation, Theme } from '@emotion/react';
import { SpaceValue } from '@formic/styles';
import { Children, FC, HTMLAttributes, ReactText } from 'react';
import { isElement } from 'react-is';

import { ReactElementWithRef } from '../types';
import { emotionCloneElement } from '../utils';

export type AdornmentElement =
  | ReactText
  | ReactElementWithRef<HTMLAttributes<HTMLElement>, unknown>;

export interface AdornmentProps {
  element: AdornmentElement;
  isEnd?: boolean;
  spacing?: SpaceValue;
}

const Adornment: FC<AdornmentProps> = ({ element, isEnd, spacing = 2, ...props }) => {
  const adornmentMargin: FunctionInterpolation<Theme> = (theme) => ({
    marginLeft: isEnd ? theme.spacing(spacing) : undefined,
    marginRight: !isEnd ? theme.spacing(spacing) : undefined,
  });

  return isElement(element) ? (
    Children.only(element) &&
      emotionCloneElement(element, {
        css: [
          adornmentMargin,
          {
            alignSelf: 'center',
            boxSizing: 'content-box',
            flex: '0 0 1em',
          },
        ],
        ...props,
      })
  ) : (
    <div
      css={[
        adornmentMargin,
        (theme) => ({
          alignSelf: 'baseline',
          fontSize: theme.fontSizes.small,
        }),
      ]}
      {...props}
    >
      {element}
    </div>
  );
};

export default Adornment;
