import { useEffect } from 'react';

export const useBrowserThemeColor = (color?: string): void => {
  useEffect(() => {
    if (color) {
      let meta = document.head.querySelector<HTMLMetaElement>('meta[name="theme-color"]');

      if (!meta) {
        meta = document.createElement('meta');
        meta.name = 'theme-color';
        document.head.appendChild(meta);
      }

      meta.content = color;
    }

    return () => {
      if (!color) {
        document.head.querySelector<HTMLMetaElement>('meta[name="theme-color"]')?.remove();
      }
    };
  }, [color]);
};
