import { styleFn, variant } from 'styled-system';

import {
  ColorInlineVariantProps,
  ColorTones,
  colorTones,
  ColorVariants,
  PseudoVariantProps,
} from '../theme';

export type ColorVariantPropsFactory<TColor extends ColorVariants> = (
  color: TColor,
) => PseudoVariantProps<ColorInlineVariantProps>;

const replaceValues = (
  entry: PseudoVariantProps<ColorInlineVariantProps>,
  color: string,
): PseudoVariantProps<ColorInlineVariantProps> => {
  return Object.fromEntries(
    Object.entries(entry).map(
      ([key, value]: [string, keyof ColorTones | PseudoVariantProps<ColorInlineVariantProps>]) => {
        if (typeof value == 'string')
          return [key, colorTones.includes(value) ? `${color}.${value}` : value];
        return [key, replaceValues(value, color)];
      },
    ),
  );
};

export const colorVariant = <TColor extends ColorVariants, TPropName extends string = 'color'>(
  colors: TColor[],
  variantProps: PseudoVariantProps<ColorInlineVariantProps> | ColorVariantPropsFactory<TColor>,
  propName: TPropName = 'color' as TPropName,
): styleFn => {
  const variants = Object.fromEntries(
    colors.map((color) => {
      const result = replaceValues(
        typeof variantProps == 'function' ? variantProps(color) : variantProps,
        color,
      );
      return [color, result] as const;
    }),
  ) as Record<ColorVariants, PseudoVariantProps<ColorInlineVariantProps>>;

  return variant<ColorInlineVariantProps, ColorVariants>({
    prop: propName,
    variants,
  });
};
