import { RefObject, useRef } from 'react';

export interface UseWindowOptions {
  target?: string;
  left?: number;
  top?: number;
  width?: number;
  height?: number;
}

export type UseWindowHook = [
  RefObject<Window | null>,
  (url: string, options?: UseWindowOptions) => void,
];

export const useWindow = (): UseWindowHook => {
  const windowRef = useRef<Window | null>(null);

  const openWindow: UseWindowHook[1] = (url, options = {}) => {
    windowRef.current = window.open(url, options.target);
  };

  return [windowRef, openWindow];
};
