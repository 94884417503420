import type * as CSS from 'csstype';
import type * as StyledSystem from 'styled-system';

import type { TextColorProps } from '../system/textColor';

export interface ColorLevels {
  '50': string;
  '100': string;
  '200': string;
  '300': string;
  '400': string;
  '500': string;
  '600': string;
  '700': string;
  '800': string;
  '900': string;
}

export interface ColorTones extends ColorLevels {
  main: string;
}

export const colorTones: (keyof ColorTones)[] = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
];

export interface MonochromeColors {
  black: string;
  white: string;
}
export const monochromeColorsKeys: (keyof MonochromeColors)[] = ['black', 'white'];

export interface CommonColors {
  neutral: ColorTones;
}
export const commonColorsKeys: (keyof CommonColors)[] = ['neutral'];

export interface MainColorPalette {
  primary: ColorTones;
  secondary: ColorTones;
}
export type MainColorVariants = keyof MainColorPalette;
export const mainColorVariants: MainColorVariants[] = ['primary', 'secondary'];

export interface StateColorPalette {
  danger: ColorTones;
  success: ColorTones;
  warning: ColorTones;
  info: ColorTones;
}
export type StateColorVariants = keyof StateColorPalette;
export const stateColorVariants: StateColorVariants[] = ['danger', 'success', 'warning', 'info'];

export interface ColorPalette extends MainColorPalette, StateColorPalette {}
export type ColorVariants = keyof ColorPalette;
export const colorVariants: ColorVariants[] = [...mainColorVariants, ...stateColorVariants];

export interface Colors extends MonochromeColors, CommonColors, ColorPalette {}

export type ColorValue =
  | keyof MonochromeColors
  | `${keyof ColorPalette | keyof CommonColors}.${keyof ColorTones}`
  | CSS.Property.All;

// For specifying the color in an inline variant.
export interface ColorInlineVariantProps
  extends StyledSystem.ColorProps<StyledSystem.RequiredTheme, keyof ColorTones | ColorValue>,
    StyledSystem.BackgroundColorProps<StyledSystem.RequiredTheme, keyof ColorTones | ColorValue>,
    StyledSystem.BorderColorProps<StyledSystem.RequiredTheme, keyof ColorTones | ColorValue>,
    StyledSystem.OpacityProps {}

// For specifying the color in a React component - 'textColor' replaces 'color'.
export interface ColorProps
  extends TextColorProps<StyledSystem.RequiredTheme, ColorValue>,
    StyledSystem.BackgroundColorProps<StyledSystem.RequiredTheme, ColorValue>,
    StyledSystem.OpacityProps {}

export interface BorderColorProps
  extends StyledSystem.BorderColorProps<StyledSystem.RequiredTheme, ColorValue> {}
