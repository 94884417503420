import deepmerge from 'deepmerge';

import { createColors } from './createColors';
import { defaultTheme } from './defaultTheme';
import { Theme } from './Theme';
import { ThemeOptions } from './ThemeOptions';
import { DeepPartial } from './types';

const pixelize = (val: string | number): string => (typeof val == 'number' ? `${val}px` : val);

export const createTheme = (themeOptions: ThemeOptions = {}): Theme => {
  const {
    box,
    dialog,
    colors,
    borderWidths,
    radii,
    space,
    header,
    heading,
    subHeading,
    sidebar,
    page,
    main,
    listItemButton,
    logo,
    organisation,
    organisationType,
    button,
    ...rest
  } = themeOptions;

  const createdTheme: DeepPartial<Theme> = {
    ...rest,
  };

  if (header) {
    createdTheme.header = { ...defaultTheme.header, ...header };
  }

  if (logo) {
    createdTheme.logo = { ...logo };
  }

  if (organisation) {
    createdTheme.organisation = { ...organisation };
  }

  if (organisationType) {
    createdTheme.organisationType = { ...organisationType };
  }

  if (box) {
    createdTheme.box = { ...defaultTheme.box, ...box };
  }

  if (dialog) {
    createdTheme.dialog = { ...defaultTheme.dialog, ...dialog };
  }

  if (heading) {
    createdTheme.heading = { ...defaultTheme.heading, ...heading };
  }

  if (subHeading) {
    createdTheme.subHeading = { ...defaultTheme.subHeading, ...subHeading };
  }

  if (page) {
    createdTheme.page = { ...defaultTheme.page, ...page };
  }

  if (main) {
    createdTheme.main = { ...defaultTheme.main, ...main };
  }

  if (sidebar) {
    createdTheme.sidebar = { ...defaultTheme.sidebar, ...sidebar };
  }

  if (listItemButton) {
    createdTheme.listItemButton = { ...defaultTheme.listItemButton, ...listItemButton };
  }

  if (button) {
    createdTheme.button = { ...button };
  }

  if (colors) {
    createdTheme.colors = createColors({ ...defaultTheme.colors, ...colors });
  }
  if (borderWidths) {
    createdTheme.borderWidths = borderWidths.map(pixelize);
  }
  if (radii) {
    createdTheme.radii = radii.map(pixelize);
  }
  if (space) {
    createdTheme.space = space.map(pixelize);
  }
  return deepmerge<Theme>(defaultTheme, createdTheme as Theme, {
    // Overwrite array instead of merging.
    arrayMerge: (destinationArray, sourceArray) => sourceArray,
  });
};
