import {
  color,
  ColorProps,
  createSystemStyles,
  layout,
  LayoutProps,
  shouldForwardProps,
  space,
  SpaceProps,
  textColor,
} from '@formic/styles';
import { ComponentType, forwardRef, SVGAttributes } from 'react';

export interface IconProps
  extends Omit<
      SVGAttributes<SVGElement>,
      'color' | 'display' | 'height' | 'opacity' | 'overflow' | 'width'
    >,
    ColorProps,
    LayoutProps,
    SpaceProps {
  component?: ComponentType<SVGAttributes<SVGElement>>;
}

const Icon = forwardRef<SVGElement, IconProps>(function Icon({ component, ...props }, ref) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: ComponentType<any> | undefined = component;
  return Component ? (
    <Component
      ref={ref}
      css={[
        {
          width: '1em',
          height: '1em',
        },
        createSystemStyles(color, textColor, layout, space)(props),
      ]}
      {...shouldForwardProps(props)}
    />
  ) : null;
});

export default Icon;
