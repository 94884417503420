import { forwardRef } from 'react';

import ButtonBase, { ButtonBaseProps } from '../ButtonBase/ButtonBase';

export interface SelectToggleButtonProps extends ButtonBaseProps {
  isOpen?: boolean;
}

const SelectToggleButton = forwardRef<HTMLButtonElement, SelectToggleButtonProps>(
  function SelectToggleButton({ isOpen, ...props }, ref) {
    return (
      <ButtonBase
        css={(theme) => ({
          outline: 'none',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: '2rem',

          '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            right: 1,
            top: 3,
            bottom: 3,
            width: '1.8em',
            backgroundImage: `linear-gradient(45deg, transparent 50%, gray 50%),
                            linear-gradient(135deg, gray 50%, transparent 50%)`,
            backgroundPosition: `calc(50% - 3px) 50%,
                               calc(50% + 3px) 50%`,
            backgroundSize: `6px 6px, 6px 6px`,
            backgroundRepeat: 'no-repeat',
            transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
            // transformOrigin: '50% 3px',
            transition: theme.transition(['transform', 'slow']),
          },

          '&:disabled': {
            backgroundColor: 'transparent',
            '&::after': {
              borderTopColor: theme.colors.neutral[300],
            },
          },
        })}
        ref={ref}
        {...props}
      />
    );
  },
);

export default SelectToggleButton;
