export const shallowEqual = <T>(obj1: T, obj2: T): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  if (!obj1 || !obj2) {
    return false;
  }

  const aKeys = Object.keys(obj1);
  const bKeys = Object.keys(obj2);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    const key = aKeys[i] as keyof T;

    if (obj1[key] !== obj2[key] || !Object.prototype.hasOwnProperty.call(obj2, key)) {
      return false;
    }
  }

  return true;
};

interface ToKeyedObjectOptions {
  lowercaseKeys?: boolean;
}

export const toKeyedObject = <T>(
  items: T[],
  key: keyof T,
  { lowercaseKeys }: ToKeyedObjectOptions = {},
): { [key: string]: T } => {
  return Object.fromEntries(
    items.map((item) => {
      const newKey = item[key];
      return [typeof newKey == 'string' && lowercaseKeys ? newKey.toLowerCase() : newKey, item];
    }),
  );
};

export const objectFilter = <T extends Record<string, unknown>>(
  items: T,
  predicate: (entry: [keyof T, T[keyof T]]) => boolean,
): T => {
  return Object.entries(items).reduce<T>((result, item) => {
    if (predicate(item as [keyof T, T[keyof T]]))
      result[item[0] as keyof T] = item[1] as T[keyof T];
    return result;
  }, {} as T);
};

type Change<T> = { from: T[keyof T]; to: T[keyof T] };

export const getObjectDiff = <T extends Record<string, unknown>>(
  obj1: T,
  obj2: T,
): { [K in keyof T]: Change<T> } => {
  const allKeys: (keyof T)[] = Object.keys({ ...obj1, ...obj2 });

  return allKeys.reduce((acc, key) => {
    if (obj1[key] !== obj2[key]) {
      acc[key] = {
        from: obj1[key],
        to: obj2[key],
      };
    }

    return acc;
  }, {} as { [K in keyof T]: Change<T> });
};
