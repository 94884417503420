import { useEffect, useRef, useState } from 'react';

export const useTimeout = <T extends () => unknown>(cb: T, delay: number): (() => void) => {
  const [isActive, setIsActive] = useState(false);
  const savedCallback = useRef<T>();

  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    let timeout: number;
    if (isActive && savedCallback.current) {
      timeout = window.setTimeout(savedCallback.current, delay);
      setIsActive(false);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [delay, isActive]);

  return () => setIsActive(true);
};
