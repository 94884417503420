import { CSSProperties, HTMLAttributes, Ref, useEffect, useLayoutEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import { useMergedRef } from '../hooks';

export interface UseSelectDisplayOptions {
  inputRef?: Ref<HTMLInputElement>;
  menuRef?: Ref<HTMLElement>;
}

export interface UseSelectDisplayHook {
  menuStyles: CSSProperties;
  menuAttributes: HTMLAttributes<HTMLElement>;
  inputRef?: Ref<HTMLInputElement>;
  menuRef?: Ref<HTMLElement>;
  focusInput: () => void;
}

export const useSelectDisplay = ({
  inputRef,
  menuRef,
}: UseSelectDisplayOptions = {}): UseSelectDisplayHook => {
  /**
   * Callback refs to hold the input and menu element respectively.
   * Unlike ref objects these trigger a rerender when set which is required for Popper to work
   * correctly.
   */
  const [inputElement, setInputElement] = useState<HTMLElement | null>(null);
  const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
  const { styles, attributes, update } = usePopper(inputElement, menuElement, {
    placement: 'bottom-start',
  });

  useEffect(() => {
    let resizeObserver: ResizeObserver | undefined;

    if (inputElement && update) {
      resizeObserver = new ResizeObserver(() => {
        void update();
      });
      resizeObserver.observe(inputElement, { box: 'border-box' });
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [inputElement, update]);

  // Sets the menu width to the same as the input element width.
  const [menuWidth, setMenuWidth] = useState(0);
  useLayoutEffect(() => {
    const width = inputElement ? inputElement.getBoundingClientRect().width : 0;
    setMenuWidth(width);
  }, [inputElement]);

  const mergedInputRef = useMergedRef<HTMLInputElement>(setInputElement, inputRef);
  const mergedMenuRef = useMergedRef<HTMLElement>(setMenuElement, menuRef);

  const focusInput = () => {
    inputElement?.focus();
  };

  return {
    menuStyles: { ...styles.popper, width: menuWidth },
    menuAttributes: attributes.popper ? attributes.popper : {},
    inputRef: mergedInputRef,
    menuRef: mergedMenuRef,
    focusInput,
  };
};
