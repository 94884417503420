import { useFuzzySearch, UseFuzzySearchOptions } from '../Util';
import {
  useCombobox,
  UseComboboxGetComboboxPropsOptions,
  UseComboboxGetToggleButtonPropsOptions,
} from 'downshift';
import { useCallback, useMemo, useState } from 'react';

import { UseSelectBaseHook, UseSelectBaseOptions } from '../SelectBase/types';
import { useSelection, UseSelectionHook } from '../SelectBase/useSelection';

export interface UseComboboxOptions<T> extends UseSelectBaseOptions<T> {}

export interface UseComboboxHook<T>
  extends UseSelectBaseHook<T>,
    Pick<UseSelectionHook<T>, 'getSelectedItemProps' | 'removeSelectedItem'> {
  filteredItems: T[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getComboboxProps: (options?: UseComboboxGetComboboxPropsOptions) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getToggleButtonProps: (options?: UseComboboxGetToggleButtonPropsOptions) => any;
}

const defaultItemToString = (item: unknown) => item as string;

const useComboboxBehavior = <T>({
  items,
  onChange,
  multiple,
  value = multiple ? [] : null,
  itemToString,
  scrollToItemRef,
  onMenuStateChange,
}: UseComboboxOptions<T>): UseComboboxHook<T> => {
  const {
    selectedItems,
    handleItemSelection,
    getDropdownProps,
    getSelectedItemProps,
    removeSelectedItem,
  } = useSelection<T>({
    items,
    onChange,
    value,
    itemToString,
    multiple,
  });

  // Text input to enter query
  const [inputValue, setInputValue] = useState('');
  const clearInputValue = () => setInputValue('');

  // SEARCHING
  const fuseItems = useMemo<T[]>(() => {
    if (selectedItems) {
      return items.filter((item) => !selectedItems.includes(item));
    }
    return items;
  }, [items, selectedItems]);

  const firstItem = items[0];
  const fuzzyOptions = useMemo<UseFuzzySearchOptions<unknown> | undefined>(() => {
    return firstItem == null || typeof firstItem == 'string'
      ? // If the items are just strings no need to apply any options
        undefined
      : // If they're complex items, allow its keys to be searched
        { fuseOptions: { keys: Object.keys(firstItem) } };
  }, [firstItem]);

  const filteredItems = useFuzzySearch(fuseItems, inputValue, fuzzyOptions);

  const {
    getComboboxProps,
    getInputProps,
    getLabelProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    selectItem,
    selectedItem,
  } = useCombobox<T>({
    inputValue,
    items: filteredItems,
    selectedItem: multiple ? null : (value as T),
    itemToString: itemToString || defaultItemToString,
    onHighlightedIndexChange: ({ highlightedIndex = -1 }) => {
      highlightedIndex > 0 && scrollToItemRef.current?.scrollToIndex(highlightedIndex);
    },
    onStateChange: ({ inputValue, type, selectedItem: changedItem, isOpen }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange: {
          inputValue != null && setInputValue(inputValue);
          handleItemSelection(undefined);
          break;
        }

        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem: {
          // When we update the selected item outside of this component, clear the input value
          if (multiple) {
            clearInputValue();
          } else {
            selectedItem &&
              setInputValue(
                itemToString ? itemToString(selectedItem) : (selectedItem as unknown as string),
              );
          }
          break;
        }

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur: {
          // Item was selected.
          if (changedItem) {
            handleItemSelection(changedItem);
            // Multiple selections state is managed by useItemSelection so we pass null here
            selectItem(multiple ? (null as unknown as T) : changedItem);

            if (multiple) {
              // Reset the input ready for the next item
              clearInputValue();
            } else {
              // If it's not multiple, keep the selected item inside the input
              setInputValue(
                itemToString ? itemToString(changedItem) : (changedItem as unknown as string),
              );
            }
          } else if (type !== useCombobox.stateChangeTypes.InputBlur) {
            // If no item was selected, clear the input
            clearInputValue();
            selectItem(null as unknown as T);
            handleItemSelection(undefined);
          }

          break;
        }

        default:
          break;
      }

      // isOpen can be undefined indicating that its state did not change.
      if (typeof isOpen == 'boolean') {
        onMenuStateChange?.(isOpen);
      }
    },
  });

  /* useEffect(() => {
    // If the currently selected item was removed from the list, unselect it.
    if (
      !multiple &&
      selectedItem != null  &&
      !filteredItems.find((item) =>
        itemToString ? itemToString(item) === itemToString(selectedItem) : item === selectedItem,
      )
    ) {
      console.log(
        'remove',
        itemToString ? filteredItems.map((item) => itemToString(item)) : filteredItems,
      );
      handleItemSelection(undefined);
      selectItem(null as never);
      clearInputValue();
    }
  }, [filteredItems, handleItemSelection, itemToString, multiple, selectItem, selectedItem]); */

  return {
    removeSelectedItem,
    filteredItems,
    getSelectedItemProps,
    getComboboxProps,
    getInputProps: useCallback(
      (options) => {
        return getInputProps(
          getDropdownProps({
            preventKeyAction: isOpen,
            ...options,
          }),
        );
      },
      [getDropdownProps, getInputProps, isOpen],
    ),
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    isOpen,
    selected: multiple ? selectedItems : selectedItem,
  };
};

export default useComboboxBehavior;
