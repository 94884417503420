import { Container, Main, PageSpinner } from '@formic/components';
import React from 'react';

const LoadingScreen: React.FC = () => {
  return (
    <Container layout="none">
      <Main>
        <PageSpinner />
      </Main>
    </Container>
  );
};

export default LoadingScreen;