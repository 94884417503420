import { useEffect, useRef, useState } from 'react';

type Bounds = Omit<DOMRectReadOnly, 'toJSON'>;

export const useMeasure = <T extends Element = Element>(): [
  React.MutableRefObject<T | null>,
  Bounds,
] => {
  const [bounds, setBounds] = useState<Bounds>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const ref = useRef<T | null>(null);
  const resizeObserver = useRef(new ResizeObserver((entries) => setBounds(entries[0].contentRect)));

  useEffect(() => {
    const element = ref.current;
    const ro = resizeObserver.current;

    element && ro.observe(element, { box: 'border-box' });
    return () => void element && ro.disconnect();
  });

  return [ref, bounds];
};
