import { forwardRef, ReactElement, useRef } from 'react';

import { ButtonBaseProps } from '../ButtonBase/ButtonBase';
import { useMergedRef } from '../hooks';
import Input, { InputProps } from '../Input/Input';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import SelectToggleButton from '../SelectBase/SelectToggleButton';

interface MultipleComboboxInputProps<T> extends InputProps {
  selectedItems: T[];
  renderSelectedItem: (item: T, index: number) => ReactElement | null;
  innerInputProps: InputBaseProps;
  isOpen?: boolean;
  toggleButtonProps: ButtonBaseProps;
}

const MultipleComboboxInput = forwardRef<HTMLInputElement, MultipleComboboxInputProps<unknown>>(
  function MultipleComboboxInput(
    {
      color,
      innerInputProps,
      selectedItems,
      renderSelectedItem,
      toggleButtonProps,
      isOpen,
      disabled,
      ...props
    },
    ref,
  ) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const mergedInputRef = useMergedRef(inputRef, innerInputProps.ref);

    const handleComboboxClick = () => inputRef.current?.focus();

    return (
      <Input
        as="div"
        color={color}
        aria-disabled={disabled}
        css={(theme) => ({
          position: 'relative',
          cursor: 'text',
          display: 'flex',
          flexWrap: 'wrap',
          padding: theme.spacing(2, 2, 0, 2),
        })}
        ref={ref}
        onClick={handleComboboxClick}
        {...props}
      >
        {selectedItems.map((selectedItem, index) => renderSelectedItem(selectedItem, index))}
        <InputBase
          css={(theme) => ({
            backgroundColor: 'transparent',
            margin: theme.spacing('1px', '1px', 2, '1px'),
            width: 0,
            flex: '1 1 50px',
          })}
          {...innerInputProps}
          ref={mergedInputRef}
        />
        <SelectToggleButton {...toggleButtonProps} isOpen={isOpen} aria-label="toggle menu" />
      </Input>
    );
  },
);

export default MultipleComboboxInput;
