import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
  color,
  compose,
  display,
  flexbox,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  grid,
  layout,
  position,
  size,
  space,
  styleFn,
  textAlign,
  variant,
} from 'styled-system';

const allPropNames: string[] =
  compose(
    display,
    flexbox,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    grid,
    layout,
    position,
    space,
    textAlign,
  ).propNames || [];

const styledShouldForwardProp = createShouldForwardProp(allPropNames);

export const shouldForwardProp = (prop: string): boolean => {
  if (prop === 'color') return false;
  return styledShouldForwardProp(prop);
};

export const shouldForwardProps = <P>(
  props: P,
  shouldForwardPropFn: (prop: string) => boolean = shouldForwardProp,
): P => {
  return Object.fromEntries(
    Object.entries(props).filter((prop) => shouldForwardPropFn(prop[0])),
  ) as P;
};

export type { styleFn };
export {
  color,
  compose,
  display,
  flexbox,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  grid,
  layout,
  position,
  size,
  space,
  textAlign,
  variant,
};
