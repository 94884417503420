import convert from 'color-convert';

import { ColorLevels, Colors, ColorTones } from './Colors';
import { ColorsOptions } from './ThemeOptions';

const intensityMap: { [K in keyof ColorLevels]: number } = {
  '50': 0.95,
  '100': 0.9,
  '200': 0.75,
  '300': 0.6,
  '400': 0.3,
  '500': 0,
  '600': 0.9,
  '700': 0.75,
  '800': 0.6,
  '900': 0.49,
};

const CMY_HUES = [180, 300, 60];
const RGB_HUES = [360, 240, 120, 0];
const INTENSITY_ADJUST = 0.5;

const getHueShiftAmount = (hues: number[], hue: number, intensity: number) => {
  // Which hue is closest to shift towards?
  const closestHue = [...hues].sort((a, b) => Math.abs(a - hue) - Math.abs(b - hue))[0];

  const hueShift = closestHue - hue;
  return Math.round(intensity * hueShift * INTENSITY_ADJUST);
};

const realWorldLighten = (hex: string, intensity: number): string => {
  const [h, s, v] = convert.hex.hsv(hex);
  const hNew = h + getHueShiftAmount(CMY_HUES, h, intensity);
  const sNew = s - Math.round(s * intensity);
  const vNew = v + Math.round((100 - v) * intensity);

  return '#' + convert.hsv.hex([hNew, sNew, vNew]);
};

const realWorldDarken = (hex: string, intensity: number): string => {
  const inverseIntensity = 1 - intensity;
  const [h, s, v] = convert.hex.hsv(hex);
  const hNew = h + getHueShiftAmount(RGB_HUES, h, inverseIntensity);
  const sNew = s + Math.round((100 - s) * inverseIntensity);
  const vNew = v - Math.round(v * inverseIntensity);

  return '#' + convert.hsv.hex([hNew, sNew, vNew]);
};

export const createVariant = (variant: string | ColorLevels): ColorTones => {
  const color = typeof variant != 'string' ? variant[500] : variant;
  const levels = Object.entries(intensityMap).reduce<ColorLevels>((acc, [level, intensity]) => {
    const levelInt = parseInt(level);
    const newColor =
      levelInt === 500
        ? color
        : levelInt < 500
        ? realWorldLighten(color, intensity)
        : realWorldDarken(color, intensity);
    return Object.assign(acc, { [level]: newColor });
  }, {} as ColorLevels);

  return {
    ...levels,
    main: levels[500],
  };
};

type TranslatedColorOptions = {
  [K in keyof ColorsOptions]: ColorTones | string extends ColorsOptions[K]
    ? ColorTones
    : ColorsOptions[K];
};

export const createColors = ({ black, white, ...palette }: ColorsOptions): Partial<Colors> => {
  return Object.entries(palette).reduce<Partial<Colors>>(
    (acc, [colorName, value]: [string, string | ColorTones]) => {
      if (typeof value == 'function') return acc;
      acc[colorName as keyof TranslatedColorOptions] = createVariant(value) as string & ColorTones;
      return acc;
    },
    {
      black,
      white,
    },
  );
};
