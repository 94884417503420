import { debounce } from 'lodash-es';

const STATUS_DIV_ID = 'a11y-status-message-custom';

/**
 * Allows us to manually set the a11y message when we need to.
 * Taken from https://github.com/downshift-js/downshift/blob/master/src/set-a11y-status.js
 */

export const setA11yStatus = (status: string): void => {
  if (!status) return;

  const div = getStatusDiv();
  if (!div) return;

  div.textContent = status;
  cleanupStatus();
};

const cleanupStatus = debounce(() => {
  const div = getStatusDiv();
  if (div) div.textContent = '';
}, 500);

const getStatusDiv = (): HTMLElement => {
  let statusDiv = document.getElementById(STATUS_DIV_ID);
  if (statusDiv) {
    return statusDiv;
  }

  statusDiv = document.createElement('div');
  statusDiv.setAttribute('id', STATUS_DIV_ID);
  statusDiv.setAttribute('role', 'status');
  statusDiv.setAttribute('aria-live', 'polite');
  statusDiv.setAttribute('aria-relevant', 'additions text');
  Object.assign(statusDiv.style, {
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
  });
  document.body.appendChild(statusDiv);
  return statusDiv;
};
