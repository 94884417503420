import React, { FC, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

const Home = lazy(() => import('./screens/Home/Home'));
const ProjectByTag = lazy(() => import('./screens/Project/ProjectByTag'));
const Complete = lazy(() => import('./screens/Complete/Complete'));
const ProjectPrint = lazy(() => import('./screens/Project/ProjectPrint'));
const Project = lazy(() => import('./screens/Project/Project'));

const AppRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path="/saved" component={Home} />
      <Route exact path="/download" component={Home} />
      <Route exact path="/tag/:tagName" component={ProjectByTag} />
      <Route exact path="/:id/complete" component={Complete} />
      <Route exact path="/:id/print" component={ProjectPrint} />
      <Route exact path="/:id" component={Project} />
      <Route exact path="/" component={Home} />
    </Switch>
  );
};

export default AppRoutes;
