import env from '@formic/client/src/env';

/**
 * Format:
 * 1 - header
 * 2 - sidebar
 *
 * to hide both header and sidebar. value must be 3.
 */

export const useSearchProjectHiddenUIOptions = (
  projectId: number,
): { projectId: number; hideSidebar: boolean; hideHeader: boolean } | undefined => {
  const projectHiddenUIOptions =
    env.FUSION_OF_NO_NAV_PROJECTS.length > 0
      ? env.FUSION_OF_NO_NAV_PROJECTS.split('|')
          .map((prjToHide) => {
            const splitted = prjToHide.split(',');

            return splitted.length === 2
              ? {
                  projectId: Number(splitted[0]),
                  hideSidebar: (Number(splitted[1]) & 2) > 0,
                  hideHeader: (Number(splitted[1]) & 1) > 0,
                }
              : {
                  projectId: 0,
                  hideSidebar: false,
                  hideHeader: false,
                };
          })
          .filter((r) => r.projectId !== 0)
      : null;

  return projectHiddenUIOptions?.find((opt) => opt.projectId === projectId);
};
