import { ColorVariantProps, createSystemStyles, LayoutProps, variant } from '@formic/styles';
import { ComponentType, forwardRef, InputHTMLAttributes } from 'react';
import { Checkbox as ReakitCheckbox, Radio as ReakitRadio } from 'reakit';

import { StandardElementProps } from '../types';
import { commonFormControlStyles } from '../utils';

export type CheckboxSizes = 'normal' | 'large' | 'small';

export type CheckboxSizeVariantProps<T extends string = 'size'> = {
  [K in T]?: CheckboxSizes;
};

export interface CheckboxBaseProps
  extends StandardElementProps<
      HTMLInputElement,
      Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'value'>
    >,
    ColorVariantProps,
    CheckboxSizeVariantProps {
  value?: string | number;
  isField?: boolean;
}

const CheckboxBase = forwardRef<HTMLInputElement, CheckboxBaseProps & { isRadio?: boolean }>(
  function CheckboxBase(
    { as: element = 'input', color, size = 'normal', type, isRadio, isField, ...props },
    ref,
  ) {
    const themeColor = color || 'neutral';
    const Component =
      !isField && isRadio
        ? ReakitRadio
        : (ReakitCheckbox as unknown as ComponentType<CheckboxBaseProps>);

    return (
      <Component
        as={element}
        ref={ref}
        css={[
          commonFormControlStyles(themeColor),
          (theme) => ({
            appearance: 'none',
            cursor: 'pointer',
            margin: 0,
            outline: 0,
            width: '1rem',
            height: '1rem',
            position: 'relative',

            '&::after': {
              content: '""',
              position: 'absolute',
              display: 'none',
              transition: theme.transition('background-color', 'border-color', 'box-shadow'),
            },

            '&:checked, &[aria-checked="true"]': {
              borderColor: theme.colors[themeColor][500],
              '&::after': {
                display: 'block',
              },
            },
          }),
          createSystemStyles(
            variant<LayoutProps, CheckboxSizes>({
              prop: 'size',
              variants: {
                small: { width: '1rem', height: '1rem' },
                normal: { width: '1.25rem', height: '1.25rem' },
                large: { width: '1.5rem', height: '1.5rem' },
              },
            }),
          )({ size }),
        ]}
        {...props}
      />
    );
  },
);

export default CheckboxBase;
