import { FunctionInterpolation, Theme } from '@emotion/react';
import { createSystemStyles, shouldForwardProps } from '@formic/styles';
import { forwardRef, HTMLAttributes } from 'react';

import { BoxStyleProps, boxSystemStyles } from '../Box/Box';
import Root from '../Root/Root';
import { StandardElementProps2 } from '../types';

export type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps
  extends StandardElementProps2<HTMLAttributes<HTMLHeadingElement>>,
    BoxStyleProps {
  level: HeadingLevels;
  variant?: HeadingLevels;
}

const headingLevels: { [K in HeadingLevels]: FunctionInterpolation<Theme> } = {
  h1: (theme) => ({
    lineHeight: 1.125,
    fontSize: theme.fontSizes.xxLarge,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }),
  h2: (theme) => ({
    lineHeight: 1.25,
    fontSize: theme.fontSizes.xLarge,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }),
  h3: (theme) => ({
    lineHeight: 1.35,
    fontSize: theme.fontSizes.larger,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
  h4: (theme) => ({
    lineHeight: 1.35,
    fontSize: theme.fontSizes.large,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
  h5: (theme) => ({
    lineHeight: 1.35,
    fontSize: theme.fontSizes.normal,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
  h6: (theme) => ({
    lineHeight: 1.35,
    fontSize: theme.fontSizes.small,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
};

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(function Heading(
  { as: element, level, variant = level, ...props },
  ref,
) {
  return (
    <Root
      as={element || level}
      ref={ref}
      css={[
        (theme) => ({
          fontFamily: theme.fonts.heading,
          fontWeight: theme.fontWeights.bold,
        }),
        headingLevels[variant],
        createSystemStyles(boxSystemStyles)(props),
      ]}
      {...shouldForwardProps(props)}
    />
  );
});

export default Heading;
