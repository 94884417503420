import { MutableRefObject, Ref, useMemo } from 'react';

/* export const useForwardAs = <P extends { as?: ElementType }>(
  Component: ComponentType<P>,
  elementType: ElementType,
): ComponentType<P> => {
  const NewComponent = useCallback<FC<P>>((props: P) => <Component as={elementType} {...props} />, [
    Component,
    elementType,
  ]);
  NewComponent.displayName = `${Component.displayName} (ForwardAs ${
    typeof elementType == 'string' ? elementType : elementType.displayName
  })`;
  return NewComponent;
}; */

const setRef = <T extends unknown>(ref: Ref<T> | undefined, value: T): void => {
  if (typeof ref == 'function') {
    ref(value);
  } else if (ref != null) {
    (ref as MutableRefObject<T>).current = value;
  }
};

export const useMergedRef = <T extends unknown>(ref1?: Ref<T>, ref2?: Ref<T>): Ref<T> => {
  return useMemo(
    () => (value: T) => {
      setRef(ref1, value);
      setRef(ref2, value);
    },
    [ref1, ref2],
  );
};
