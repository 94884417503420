import { Config, RequiredTheme, ResponsiveValue, system, Theme, ThemeValue } from 'styled-system';

export type TextColorProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = ThemeValue<'colors', ThemeType>,
> = {
  textColor?: ResponsiveValue<TVal, ThemeType>;
};

const config: Config = {
  textColor: {
    property: 'color',
    scale: 'colors',
  },
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors',
  },
  opacity: true,
};

export const textColor = system(config);
