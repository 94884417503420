import { RefObject, useCallback } from 'react';
import scrollIntoViewIfNeeded, { Options } from 'scroll-into-view-if-needed';

export const useScrollIntoView = <T extends Element>(
  ref: RefObject<T>,
  /**
   * Default values align to Element.scrollIntoView spec.
   * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
   */
  {
    behavior = 'auto',
    block = 'start',
    inline = 'nearest',
    scrollMode,
  }: Pick<Options, 'behavior' | 'block' | 'inline' | 'scrollMode'> = {},
): (() => void) => {
  return useCallback(() => {
    if (ref.current) {
      scrollIntoViewIfNeeded(ref.current, {
        behavior,
        block,
        inline,
        scrollMode,
      });
    }
  }, [behavior, block, inline, ref, scrollMode]);
};
