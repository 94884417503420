import { Util } from '@formic/components';
import type { User } from 'oidc-client';
import { createContext, FC, useEffect, useMemo, useRef, useState } from 'react';

import type { AuthService } from './AuthService';

export interface AuthContextType {
  user: User | null;
  login(): Promise<void>;
  register(): Promise<void>;
  logout(): Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export interface AuthProviderProps {
  service: AuthService | (() => Promise<AuthService>);
}

const getAuthServiceFactory = (
  service: AuthService | (() => Promise<AuthService>),
): (() => Promise<AuthService>) => {
  // Using instanceof means we would need to import AuthService as a value which would massively
  // increase our bundle size.
  if ('userManager' in service) return () => Promise.resolve(service);
  return service;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children, service }) => {
  const getAuthService = useRef(getAuthServiceFactory(service));

  useEffect(() => {
    getAuthService.current = getAuthServiceFactory(service);
  }, [service]);

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    void getAuthService
      .current()
      .then((auth) => auth.getUser())
      .then(setUser);
  }, [getAuthService]);

  const value = useMemo(() => {
    return {
      user,
      login: () => getAuthService.current().then((auth) => auth.login()),
      register: () => getAuthService.current().then((auth) => auth.register()),
      logout: () => getAuthService.current().then((auth) => auth.logout()),
    };
  }, [getAuthService, user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthService = Util.makeContextConsumerHook(AuthContext);
