import { Interpolation, Theme, useTheme } from '@emotion/react';
import { ColorVariantProps } from '@formic/styles';
import { forwardRef, HTMLAttributes } from 'react';

import Adornment from '../Adornment/Adornment';
import Box from '../Box/Box';
import ButtonBase, { ButtonBaseProps } from '../ButtonBase/ButtonBase';
import Flex from '../Flex/Flex';
import { ReactElementWithRef } from '../types';

export interface ButtonProps extends ButtonBaseProps, ColorVariantProps {
  iconStart?: ReactElementWithRef<HTMLAttributes<HTMLElement>, unknown>;
  iconEnd?: ReactElementWithRef<HTMLAttributes<HTMLElement>, unknown>;
}

const buttonIconStyles: Interpolation<Theme> = {
  width: '1em',
  height: '1em',
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, color, size = 'normal', iconStart, iconEnd, ...props },
  ref,
) {
  const selectedTheme = useTheme();

  if (selectedTheme?.button?.iconColor !== undefined)
    buttonIconStyles.color = selectedTheme.button.iconColor;

  return (
    <ButtonBase
      ref={ref}
      size={size}
      css={[
        (theme) => ({
          ...theme.colors.readable(theme.colors.neutral[200]),

          '&:focus': {
            boxShadow: `0 0 0 3px ${theme.colors.neutral[400]}`,
          },

          '&:hover': {
            backgroundColor: theme.colors.neutral[300],
          },

          '&:active': {
            backgroundColor: theme.colors.neutral[400],
          },

          '&:disabled': {
            backgroundColor: theme.colors.neutral[200],
            color: theme.colors.neutral[400],
            cursor: 'not-allowed',
          },
        }),

        color &&
          ((theme) => ({
            ...theme.colors.readable(theme.colors[color][500]),

            '&:focus': {
              boxShadow: `0 0 0 3px ${theme.colors[color][300]}`,
            },

            '&:hover': {
              backgroundColor: theme.colors[color][600],
            },

            '&:active': {
              backgroundColor: theme.colors[color][700],
            },
          })),

        color &&
          selectedTheme &&
          selectedTheme.button &&
          selectedTheme?.button[color] &&
          ((theme) => ({
            ...theme.colors.readable(
              theme.button?.[color]?.backgroundColor ?? theme.colors[color][500],
            ),

            '&:focus': {
              boxShadow: `0 0 0 3px ${
                theme.button?.[color]?.focusbgColor ?? theme.colors[color][300]
              }`,
            },

            '&:hover': {
              backgroundColor: theme.button?.[color]?.hoverbgColor ?? theme.colors[color][600],
            },

            '&:active': {
              backgroundColor: theme.button?.[color]?.activebgColor ?? theme.colors[color][700],
            },
          })),
      ]}
      {...props}
    >
      {iconStart || iconEnd ? (
        <Flex center>
          {iconStart && <Adornment css={buttonIconStyles} element={iconStart} spacing={2} />}
          <Box flexGrow={1}>{children}</Box>
          {iconEnd && <Adornment isEnd css={buttonIconStyles} element={iconEnd} spacing={2} />}
        </Flex>
      ) : (
        children
      )}
    </ButtonBase>
  );
});

export default Button;
