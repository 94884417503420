import { forwardRef } from 'react';

import Box, { BoxProps } from '../Box/Box';

export interface MainProps extends BoxProps {
  centered?: boolean;
}

const Main = forwardRef<HTMLElement, MainProps>(function Main(
  { centered, children, ...props },
  ref,
) {
  return (
    <Box
      ref={ref}
      css={(theme) => ({
        backgroundColor: theme.main?.backgroundColor,
        overflow: 'auto',
        width: '100%',
        height: '100%'
      })}
      as="main"
      p={4}
      justifySelf={centered ? 'center' : undefined}
      {...props}
    >
      <a id="maincontent" tabIndex={-1}></a>
      {children}
    </Box>
  );
});

export default Main;
