import { Avatar, Box, Button, Icon, Link, mediaQueries } from '@formic/components';
import React, { FC, MouseEvent, ReactNode } from 'react';
import { User, UserPlus } from 'react-feather';

import { useAuthService } from './AuthContext';

interface HeaderUserStatusProps {
  children?: ReactNode;
  loginEnabled?: boolean;
  registerEnabled?: boolean;
}

const HeaderUserStatus: FC<HeaderUserStatusProps> = ({
  children,
  loginEnabled,
  registerEnabled,
}) => {
  const { login, register, logout, user } = useAuthService();

  const handleLink = (evt: MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    void (user ? logout() : login());
  };

  return (
    <Box
      as="section"
      aria-label="Login Status"
      css={[
        () => {
          return {
            display: 'flex',
            alignItems: 'center',

            '> button > div > div': {
              [`${mediaQueries.small}`]: {
                display: 'none',
              },
            },
          };
        },
      ]}
    >
      {user ? (
        <>
          <Box marginRight="8px" display="flex" flexDirection="column">
            <Box
              fontSize="small"
              css={(theme) => ({
                color: theme.header?.color
                  ? theme.header.color
                  : theme.fonts?.color
                  ? theme.fonts.color
                  : theme.colors.black,
              })}
            >
              {user ? user.profile.name || user.profile.preferred_username : 'Not logged in'}
            </Box>
            <Box fontSize="small" marginLeft="auto">
              <Link to="#" onClick={handleLink}>
                {user ? 'Logout' : 'Login'}
              </Link>
            </Box>
          </Box>
          <Avatar marginRight="8px" bg={user ? 'primary.main' : 'neutral.300'}>
            <Icon component={User} css={user && ((theme) => ({ color: theme.colors.white }))} />
          </Avatar>
        </>
      ) : (
        <>
          {loginEnabled && (
            <Button
              color="primary"
              onClick={login}
              height={32}
              marginRight="8px"
              iconStart={<User />}
            >
              Login
            </Button>
          )}

          {loginEnabled && registerEnabled && (
            <>
              <Box
                css={(theme) => ({
                  color: theme.header?.color
                    ? theme.header.color
                    : theme.fonts?.color
                    ? theme.fonts.color
                    : theme.colors.black,
                })}
              >
                or
              </Box>
              <Button
                color="primary"
                onClick={register}
                height={32}
                marginLeft="8px"
                marginRight="8px"
                iconStart={<UserPlus />}
              >
                Register
              </Button>
            </>
          )}
        </>
      )}
      {children}
    </Box>
  );
};

export default HeaderUserStatus;
