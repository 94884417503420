import env from '@formic/client/src/env';

/**
 * Format:
 * 1 - Previous
 * 2 - Next
 * 4 - Submit
 * to hide all 3, value must be 7.
 */

export const useProjectHiddenNavigationOptions = (
  projectId: number,
): {
  projectId: number;
  hidePrevious: boolean;
  hideNext: boolean;
  hideSubmit: boolean;
} => {
  const projectHiddenNavigationOptions =
    env.FUSION_OF_NO_NAVBUTTON_PROJECTS.length > 0
      ? env.FUSION_OF_NO_NAVBUTTON_PROJECTS.split('|')
          .map((prjToHide) => {
            const splitted = prjToHide.split(',');

            return splitted.length === 2
              ? {
                  projectId: Number(splitted[0]),
                  hidePrevious: (Number(splitted[1]) & 1) > 0,
                  hideNext: (Number(splitted[1]) & 2) > 0,
                  hideSubmit: (Number(splitted[1]) & 4) > 0,
                }
              : {
                  projectId: 0,
                  hidePrevious: false,
                  hideNext: false,
                  hideSubmit: false,
                };
          })
          .filter((r) => r.projectId !== 0)
      : null;

  return (
    projectHiddenNavigationOptions?.find((opt) => opt.projectId === projectId) ?? {
      projectId: projectId,
      hidePrevious: false,
      hideNext: false,
      hideSubmit: false,
    }
  );
};
