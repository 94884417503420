import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { AuthProvider } from '@formic/auth';
import { ClientProvider, createClient } from '@formic/fusion-graphql';
import React, { FC, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './App.routes';
import { getAuthService } from './authService';
import env from './env';
import postRespondentUpdate from './graphql/postRespondentUpdate';
import MainLayout from './layout/MainLayout';
import LoadingScreen from './LoadingScreen';

const cache = createCache({
  key: 'my-prefix-key',
  stylisPlugins: [],
});

const client = createClient({
  url: env.FUSION_OF_GRAPHQL_URI,
  tagNameExchange: true,
  updates: {
    Mutation: {
      ...postRespondentUpdate,
    },
  },
  getToken: () => getAuthService().then((auth) => auth.getToken()),
});

const App: FC = () => {
  return (
    <React.StrictMode>
      <CacheProvider value={cache}>
        <Suspense fallback={<LoadingScreen />}>
          <AuthProvider service={getAuthService}>
            <ClientProvider client={client}>
              <BrowserRouter>
                <MainLayout>
                  <AppRoutes />
                </MainLayout>
              </BrowserRouter>
            </ClientProvider>
          </AuthProvider>
        </Suspense>
      </CacheProvider>
    </React.StrictMode>
  );
};

export default App;
