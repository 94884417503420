import { useEffect, useRef } from 'react';

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  // Update the ref when the value changes.
  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return previous value - useEffect won't trigger a render because ref is mutable.
  return ref.current;
};
